import PropTypes from "prop-types";
import React from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import logoRoster from "../../assets/images/logo-roster.png";
//redux
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import LogoPink from "../../assets/images/LogoPink.png";
import forgetpasswordicon from "../../assets/images/forgetpasswordicon.png";


// action
import { userForgetPassword } from "../../store/actions";

// import images
// import profile from "../../assets/images/bg.png";
import logoDark from "../../assets/images/logo-dark.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import withRouter from "../../Components/Common/withRouter";

const ForgetPasswordPage = props => {

  const navigate = useNavigate();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: async (values) => {
      // Call the API to send the reset link
      try {
        const response = await axios.post('/forgot-password', {
          email: values.email,
        });
        if (response.status === "success") {
          // Handle the response as needed
          console.log('Reset link sent:', response);
          navigate('/reset-password', { state: { email: values.email } });
        }else{
          toast.error(response.message, { autoClose: 2000 });

        }

      } catch (error) {
        // Handle errors, e.g., display an error message
        console.error('Error sending reset link:', error);

        // You may want to dispatch an action based on the error
        // dispatch(resetLinkSentError(error));
      }
    }
  });

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  document.title = "Reset Password | Get Rostered";

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
            <div className="text-center mt-sm-4 mb-3 text-white-50">
                      <div>
                        <Link to="/" className="d-inline-block auth-logo">
                          <img src={LogoPink} alt="" height="48" />
                        </Link>
                      </div>
                    </div>
              <Card className=" login-card mt-4" style={{borderRadius:'40px'}}>

                <CardBody className="p-4">
                <div className="justify-content-center ">
                <Row className="justify-content-center mb-4">
                          <Col md={5} className="text-center">
                          <img
                                src={forgetpasswordicon}
                                alt=""
                                height="60"
                              />
                    
                    </Col>
                        </Row>
                  </div>
                  <Alert className="alert-borderless alert-warning text-center mb-2 mx-2" role="alert" style={{borderRadius: "10px"}}>
                    Enter your email and instructions will be sent to you!
                  </Alert>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                     <div className="mt-4 position-relative">
                     <i className="ri-mail-fill position-absolute mt-2 ms-2 icon-left" style={{ color: "grey" }}></i>
                        <Input
                          name="email"
                          className="form-control pe-5"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                          style={{ borderRadius: "10px", paddingLeft: "2.5rem" }}
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                        ) : null}
                      </div>

                      <div className="text-center mt-4">
                        <button className="btn btn-signup text-white w-100" type="submit"  style={{borderRadius: "10px",border:'none'}}>Send Reset Code</button>
                      </div>
                      <div className="mt-4 text-center">
                        <p className="mb-0">Wait, I remember my password... <Link to="/login" className="fw-semibold text-decoration-underline" style={{ fontWeight:"402",color: "black" }}> Click here </Link> </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>



            </Col>
          </Row>
          <ToastContainer/>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
