import React, { useEffect, useState } from "react";

const RedirectionPage = () => {
  const [CurrentOS, setOS] = useState();
  useEffect(() => {
    const appScheme = "work.getrostered.app://"; // Your custom app URL scheme
    const appStoreLink = "itms-apps://itunes.apple.com/in/app/getrostered/id6471218975"; // Your app's App Store link
    const playStoreLink = "https://play.google.com/store/apps/details?id=app.getrostered.work"; // Your app's Play Store link
    const webLink = "https://devwork.getrostered.app"; // Your website link

    const getMobileOperatingSystem = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    };

    const redirectToAppOrStore = () => {
      const os = getMobileOperatingSystem();
      console.log(os);
      setOS(os);
      window.location.href = appStoreLink;
      if (os === "Android") {
        window.location.href = appScheme;
        var now = new Date().valueOf();
        setTimeout(function () {
          if (new Date().valueOf() - now > 100) return;
          window.location.href = playStoreLink;
        }, 1000);
      } else if (os === "iOS") {
        window.location.href = appScheme;
        var now = new Date().valueOf();
        setTimeout(function () {
          if (new Date().valueOf() - now > 100) return;
          window.location.href = appStoreLink;
        }, 1000);
      } else {
        setTimeout(() => {
          window.location.href = webLink;
        }, 2500); // Adjust timeout as necessary
      }
    };

    redirectToAppOrStore();
  }, []);

  return (
    <div>
      <h1>Redirecting...</h1>
      <p>
        If you are not redirected automatically,{" "}
        <a id="redirect-link" href="#">
          click here
        </a>
        .
      </p>
      <p>{CurrentOS}</p>
    </div>
  );
};

export default RedirectionPage;
