import React, { useEffect, useState } from 'react';
import { Col, Input, Label, Row, CardHeader, CardBody, Accordion, AccordionItem, Collapse, Card, Container, Table } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Components
// import * as moment from "moment";
import moment from "moment";
import saveAs from "file-saver";
import BreadCrumb from '../../Components/Common/BreadCrumb';


const LeadReport = () => {
    document.title = "Lead Report | Get Roster";
    const [col1, setcol1] = useState(false);
    const [data, setData] = useState([]);
    const [source, setSource] = useState([]);
    const t_col1 = () => {
        setcol1(!col1);

    };

    //Created start date and end date
    const [createdDateStart, setCreatedDateStart] = useState(null);
    const [createdDateEnd, setCreatedDateEnd] = useState(null);
    const [selectedSourceId, setselectedSourceId] = useState("");
    const [org_date, setOrg_Date] = useState([]);

    const loadData = () => {
        axios.get("api/leads-report")
            .then(res => {
                console.log(res, "res4566");
                setOrg_Date(res.organisation_settings);
                setData(res.reports);
                setSource(res.source)
                console.log(res.source);
                setCreatedDateEnd(moment().format(res.organisation_settings?.date_format_js));
                setCreatedDateStart(moment().subtract(1, 'months').format(res.organisation_settings?.date_format_js));
            })
    }
    const handleApply = (event) => {
        event.preventDefault();
        const selectedDateFormat = org_date?.date_format;
        const filterDataS = {
            'source': selectedSourceId,
            'created_date_start': createdDateStart,
            'created_date_end': createdDateEnd,
            'selected_date_format': selectedDateFormat
        }
         const filterData = {
            'source':null,
            'created_date_start': createdDateStart,
            'created_date_end': createdDateEnd,
            'selected_date_format': selectedDateFormat
        }
        let arr = [];
        // if (selectedSourceId === "none") {
        //     console.log(selectedSourceId);
        //     toast.error('Please Select a Source', { theme: "light" });
        // }
        // else {  }
        if (selectedSourceId===""){
            axios.post("api/crm-lead-get-data", filterData)
            .then(res => {
                console.log(res.reports, "source");
                arr.push(res.reports);
                setData(res.reports);
            })
        }
        else{
            axios.post("api/crm-lead-get-data", filterDataS)
            .then(res => {
                console.log(res, "res123");
                arr.push(res);
                setData(arr);
            })
        }
        
      
    }

    const handleReset = () => {
        loadData();
        t_col1();
        setselectedSourceId('');
        setCreatedDateEnd(moment().format(org_date.date_format_js));
        setCreatedDateStart(moment().subtract(1, 'months').format(org_date.date_format_js));

    }

    useEffect(() => {
        loadData();

    }, [])

    const exportLeadReport = () => {
        const selectedDateFormat = org_date?.date_format;
        axios.get("api/exportLeadReport?created_date_start=" + createdDateStart + "&created_date_end=" + createdDateEnd + "&source=" + selectedSourceId + "&selectedDateFormat=" + selectedDateFormat)
            .then((res) => {
                console.log(res);
                const excelData = res.excelData;
                const blob = new Blob([Uint8Array.from(atob(excelData), c => c.charCodeAt(0))], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });

                saveAs(blob, "Lead Report.xlsx");
            })
            .then(err => {
                console.log(err);
            })
    }

    document.title = "Lead Reports | Get Roster";
    console.log(data, "data1234");
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Lead Report" pageTitle="Get Roster" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Accordion id="default-accordion-example">
                                        <AccordionItem>
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    className={classnames("accordion-button fw-semibold", { collapsed: !col1 })} type="button" onClick={t_col1} style={{ cursor: "pointer" }} >
                                                    Search Filters
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne" >
                                                <div className="accordion-body">

                                                    <Row>
                                                        <Col lg={2}>
                                                            <div className="mb-3">
                                                                <Label for="createdDateinput" className="form-label">Start Date</Label>
                                                                <Flatpickr
                                                                    className="form-control" placeholder='Start Date'
                                                                    options={{
                                                                        dateFormat: org_date?.date_format,
                                                                    }}
                                                                    value={createdDateStart}
                                                                    onChange={(selectedDates) => {
                                                                        if (selectedDates.length === 1) {
                                                                            setCreatedDateStart(moment(selectedDates[0]).format(org_date?.date_format_js));
                                                                        } else {
                                                                            setCreatedDateStart(null);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={2}>
                                                            <div className="mb-3">
                                                                <Label for="createdDateinput" className="form-label" >End Date</Label>
                                                                <Flatpickr
                                                                    className="form-control" placeholder='End Date'
                                                                    options={{
                                                                        dateFormat: org_date?.date_format,
                                                                    }}
                                                                    value={createdDateEnd}
                                                                    onChange={(selectedDates) => {
                                                                        if (selectedDates.length === 1) {
                                                                            setCreatedDateEnd(moment(selectedDates[0]).format(org_date?.date_format_js));
                                                                        } else {
                                                                            setCreatedDateEnd(null);
                                                                        }
                                                                    }}
                                                                />

                                                            </div>
                                                        </Col>

                                                        <Col lg={3}>
                                                            <Label for="Sortingorder" className="form-label">Source</Label>
                                                            <select
                                                                value={selectedSourceId}
                                                                onChange={(event) => setselectedSourceId(event.target.value)}
                                                                className="form-select "
                                                                aria-label="Default select example"
                                                            >
                                                                <option value="">None Selected</option>
                                                                {source?.map((item) => (
                                                                    <option key={item.id} value={item.options}>
                                                                        {item.options}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </Col>


                                                        <Col lg={5} className='d-flex'>
                                                            <div className="text-end justify-content-end hstack gap-5">
                                                                <div className='hstack gap-3'>
                                                                    <button type="submit" className="btn btn-primary"
                                                                        onClick={handleApply}
                                                                    >Apply</button>
                                                                    <button type="submit" className="btn  btn-primary"
                                                                        onClick={handleReset}
                                                                    >Reset</button>
                                                                </div>

                                                                <img src="https://dev.onexfort.com/img/excel.svg" alt="" className="" style={{ width: "10%", float: "right", cursor: "pointer" }} onClick={() => exportLeadReport()} />
                                                            </div>
                                                        </Col>


                                                    </Row>
                                                </div>
                                            </Collapse>
                                        </AccordionItem>
                                    </Accordion>
                                </CardHeader>
                                <CardBody>

                                    <Table className="table table-bordered">
                                        <thead >
                                            <tr className='text-primary text-center'>
                                                <th>Source</th>
                                                <th>Quotes Created</th>
                                                <th>Jobs Confirmed</th>
                                                <th>Quotes Lost</th>
                                                <th>Total Sales</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.map((item, index) => (
                                                <tr key={index}>
                                                    <td className='text-center'> {item.source}</td>
                                                    <td className='right-align-center-align'>{item.QuotesCreated}</td>
                                                    <td className='right-align-center-align'>{item.jobsConfirmed}</td>
                                                    <td className='right-align-center-align'>{item.QuotesLost}</td>
                                                    <td className='right-align-center-align'>{item.totalSales}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ToastContainer closeButton={false} limit={1} />
            </div>

        </React.Fragment>
    );
};

export default LeadReport;
