import { Card, CardBody,Table } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import Loader from "../../../Components/Common/Loader";




export const PagePersonalisation = () => {
  document.title="Page Personalisation | Get Roster";
const [pagePer, setPagePer] = useState([]);
const [isLoading, setisLoading] = useState(false);
const  loadPagePersonalisation = () => {
  setisLoading(true);
  axios.get('/api/page-personalisation/data')
    .then(response => {
      setPagePer(response.pages);
    })
    .catch(error => {
      console.log(error);
    })
    .finally(() => {
      setisLoading(false);
    })
}

useEffect(() => {
  loadPagePersonalisation();
}, []);

  return (
    <div>
      <Card>
        <CardBody>
          <Table className="table table-bordered table-nowrap align-middle mb-3">
            <thead>
              <tr>
                <th style={{ backgroundColor: '#d3d3d3' }} >
                  Page Name
                </th>
                <th style={{ backgroundColor: '#d3d3d3' }}>Description</th>

              </tr>
            </thead>
            <tbody>
            {pagePer.map((item, index) => (
              <tr key={item.id}>
                <td>
                <a href={`/personalisationdetail/${item.id}`}>
                  {item.page_name}
                </a>
                </td>
                <td>{item.page_desc}</td>
              </tr>
               ))}
            </tbody>
          </Table>
          {/* reloading */}
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  )
}
