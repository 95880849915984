import PrismCode from "../../../Components/Common/Prism";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, Alert, UncontrolledAlert, TabContent, TabPane, UncontrolledTooltip } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo, useRef  } from "react";
import { Button } from 'reactstrap';
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import "../../../assets/scss/pages/_settings.scss";
import {
  CardHeader,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback
} from "reactstrap";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../../Components/Common/Loader";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
//reloading the page
import { jsx } from '@emotion/react'
import { ClipLoader } from 'react-spinners';
import {api} from  "../../../config";


//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const People = () => {
   document.title="People | Onexfort";
  return (
    <div> 
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center flex-wrap gap-2">
            <div className="flex-grow-1">
            <h5 className="mb-0 text-primary">People</h5>
            </div>
            <div className="flex-shrink-0">
            </div>
          </div>
        </CardHeader>
      </Card>
    </div>
  )
}
