// import React from 'react'
import { Card, CardBody, Nav } from 'reactstrap';
import {SettingsTabPages } from '../Constants/SettingsConstants';
import TabControlNavItem from '../../../Components/Common/TabControlNavItem';
import { useNavigate } from 'react-router-dom';
import React from "react";


export default function SettingsTabControl({ activeTabSetter, activeTab }) {
    const history = useNavigate();

    function clickHandler(navObject) {
        if(navObject.displayText == "Organisation Setting"){
            history("/settings/organisation-settings");
          }else if(navObject.displayText == "Delete Account"){
            history("/settings/delete-account");
          }else if(navObject.displayText == "Terms & Conditions"){
            history("/settings/terms-conditions");
          }else if(navObject.displayText == "Privacy Policy"){
            history("/settings/privacy-policy");
          }
        activeTabSetter(navObject);
    }


      const filteredMenuItemsGeneral = SettingsTabPages;

    return (
        <Card>
            <CardBody>
                <Nav pills vertical>
                    {
                        // Object.keys(SettingsTabPages).map(tabKey =>
                        //     <TabControlNavItem navObject={SettingsTabPages[tabKey]} onClickCallback={clickHandler} key={tabKey.id} activeTab={activeTab} />
                        // )
                        Object.keys(filteredMenuItemsGeneral).map(tabKey =>
                          <TabControlNavItem navObject={filteredMenuItemsGeneral[tabKey]} onClickCallback={clickHandler} key={tabKey.id} activeTab={activeTab} />
                      )
                    }
                </Nav>
            </CardBody>
        </Card>


    )
}
