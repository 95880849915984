
export const SettingsTabPages = {
    General: {
        id: 0,
        name:"general",
        displayText: 'General',
        iconClass: 'cog'
    },
    People: {
        id: 1,
        name:"people",
        displayText: 'People',
        iconClass: 'user'
    },
    Roster: {
        id: 2,
        name:"roster",
        displayText: 'Roster',
        iconClass: 'chevron-right'
    },
    Timesheets: {
        id: 3,
        name:"time_sheets",
        displayText: 'Timesheets',
        iconClass: 'file'
    },
    Leave: {
        id: 4,
        name:"leave",
        displayText: 'Leave',
        iconClass: 'user'
    },
}

