import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import React, { useState, useEffect } from "react";
import axios from "axios";
import userDummy from "../../assets/images/users/user-dummy-img.jpg";
import { ToastContainer, toast } from "react-toastify";
import { get_cookie } from "../../helpers/get_cookie";
import { Loader } from "feather-icons-react/build/IconComponents";

const CareerInsights = () => {
  const [userId, setUserId] = useState(null);
  const [username, setUserName] = useState(null);
  const [workforce_id, setWorkforceId] = useState(null);
  const [suburbWithState, setSuburbWithState] = useState(null);
  const [answerObject, setAnswerObject] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [street, setStreet] = useState(null);
  const [fullAddress, setFullAddress] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [email, setEmail] = useState(null);
  const [userSkill, setUserSkill] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [groupedQuestions, setGroupedQuestions] = useState([]);
  const [groupedAnswers, setGroupedAnswers] = useState([]);
  const [userProfileImg, setUserProfileImg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [profileImageLoading, setProfileImageLoading] = useState(true);
  const [answers, setAnswers] = useState({});
  const [questionStates, setQuestionStates] = useState({});
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState({});
  const [imageTimestamp, setImageTimestamp] = useState(Date.now());

  // S3 Bucket URL
  // const s3ImgUrl =
    // "https://s3.ap-southeast-2.amazonaws.com/devwork.getrostered.app/workforce-profile-pictures/";

  // Assuming userProfileImg is the image URL
  // const profleImageUrl = `${s3ImgUrl}${userProfileImg}`;

  useEffect(() => {
    setIsLoading(true);
    loadData();
  }, []);

  useEffect(() => {
    // Whenever userProfileImg changes, update the timestamp
    setImageTimestamp(Date.now());
  }, [userProfileImg]);

  const loadData = async () => {
    try {
      const authUser = JSON.parse(get_cookie("workauthUser"));

      if (authUser && authUser.user_id) {
        setUserId(authUser.user_id);

        const careerResponse = await axios.get(
          `/careerdetails/${authUser.user_id}`
        );

        const questionsandanswersResponse = await axios.post(
          `/questionsandanswers`,
          {
            workforce_profile_id: authUser.user_id,
          }
        );

        if (careerResponse.data && careerResponse.message === "Success") {
          const userProfile = careerResponse.data.user_profile;
          const userDetails = careerResponse.data.user_details;
          const QandA = questionsandanswersResponse.data;

          if (
            questionsandanswersResponse.data &&
            questionsandanswersResponse.message === "Success"
          ) {
            try {
              // Parse stringified JSON within the response
              const parsedData = {
                data: {
                  workforce_questions: JSON.parse(QandA.workforce_questions),
                  workforce_answers: JSON.parse(QandA.workforce_answers),
                },
              };
              setAnswers(parsedData.data.workforce_answers); // Make sure initialAnswers is defined
              setQuestionsAndAnswers(parsedData);
              // const questionsData = JSON.parse(questionsandanswersResponse.data.workforce_questions);

              // Create a map to group questions by category
              // const groupedQuestions = new Map();

              // questionsData.forEach((category) => {
              //   const categoryName = category.category;
              //   const categoryQuestions = category.questions.map((question) => ({
              //     ...question,
              //     category: categoryName,
              //   }));

              //   if (groupedQuestions.has(categoryName)) {
              //     groupedQuestions.get(categoryName).push(...categoryQuestions);
              //   } else {
              //     groupedQuestions.set(categoryName, categoryQuestions);
              //   }
              // });

              // Flatten the groupedQuestions map into an array
              // const flattenedQuestions = Array.from(groupedQuestions.values()).flat();

              // console.log(flattenedQuestions);

              // setGroupedQuestions(flattenedQuestions);
            } catch (error) {
              console.error("Error parsing workforce_questions:", error);
            }

            // Parse the answers array
            // const parsedAnswers = answersData.map((answer) => ({
            //     question_id: answer.question_id,
            //     response_text: answer.response_text,
            // }));

            // console.log(flattenedQuestions);
            // console.log(parsedAnswers);

            // setGroupedQuestions(flattenedQuestions);
            // setGroupedAnswers(parsedAnswers);
          }

          if (userProfile) {
            const {
              first_name,
              last_name,
              workforce_id,
              suburb,
              latitude,
              longitude,
              address,
              postcode,
              email,
              workforce_skills,
              profile_pics,
              full_address,
            } = userProfile;

            setUserName(`${first_name} ${last_name}`);
            setFirstName(first_name);
            setLastName(last_name);
            setUserProfileImg(profile_pics);
            setWorkforceId(workforce_id);
            setSuburbWithState(suburb);
            setSelectedLocation({ latitude, longitude });
            setStreet(address);
            setFullAddress(full_address);
            setPostalCode(postcode);
            setEmail(email);
            setUserSkill(workforce_skills);

            const defaultSelectedSkillsOptions = workforce_skills?.map(
              (skill) => ({ value: skill.id, label: skill.name })
            );
            setSelectedSkills(defaultSelectedSkillsOptions);
          }
        } else {
          console.error("User details not found");
        }
        setIsLoading(false);
      } else {
        console.error("Error fetching user profile.");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching user profile:", error);
    }
  };

  const t_Questions = (categoryId, questionId) => {
    console.log("questionId:", questionId);
    setAnswerObject(null);
    setQuestionStates((prevStates) => ({
      ...prevStates,
      [categoryId]: {
        ...prevStates[categoryId],
        [questionId]: !prevStates[categoryId]?.[questionId],
      },
    }));
  };

  const handleInputChange = (questionId, answer) => {
    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];

      const answerIndex = updatedAnswers.findIndex(
        (item) => item.question_id === questionId
      );

      // Update the specific answer or add a new one if not found
      if (answerIndex !== -1) {
        updatedAnswers[answerIndex] = {
          question_id: questionId,
          response_text: answer,
        };
      } else {
        updatedAnswers.push({
          question_id: questionId,
          response_text: answer,
        });
      }

      // Update the state
      return updatedAnswers;
    });

    // Use 'answers' after the state has been updated
    console.log("Updated Answers:", answers);
  };

  const save_Answers = (categoryId, questionId) => {
    // Find the answer object based on questionId
    const answerObject = answers.find(
      (answer) => answer.question_id === questionId
    );

    setAnswerObject(answerObject);
    // Check if response_text is present
    if (!answerObject?.response_text) {
      toast.error("Answer Field is required.", { theme: "light" });
      return; // Stop further execution
    }

    // Your logic to save answers
    let data = {
      workforce_profile_id: workforce_id,
      question_id: questionId,
      response_text: answerObject.response_text,
    };
    axios
      .post("/addworkforceanswers", data)
      .then((res) => {
        if (res.error === 0) {
          toast.success("Answer Added Successfully", { theme: "light" });
          loadData();

          // Close the collapse when saving answers
          setQuestionStates((prevStates) => ({
            ...prevStates,
            [categoryId]: {
              ...prevStates[categoryId],
              [questionId]: false,
            },
          }));
        } else {
          toast.error(res.message, { theme: "light" });
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // .finally(() => {
    //   t_location(item.id);
    //   fetchUserProfile();
    // });
  };

  const [modal_positionBottom, setmodal_positionBottom] = useState(false);
  function tog_positionBottom() {
    setmodal_positionBottom(!modal_positionBottom);
  }

  // console.log(`${s3ImgUrl}${userProfileImg}`);

  document.title = "Career Insights | Get Rostered";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Career Insights" pageTitle="Career Insights" />
          {isLoading ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(255, 255, 255, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
              }}
            >
              {" "}
              <Loader />
            </div>
          ) : (
            <>
              <Row>
                <Col xl={12}>
                  <Card className="mb-2">
                    <CardBody>
                      <div className="profile-wrapper">
                        <Row className="g-4">
                          <div className="col-auto ">
                            <div className="avatar-xl">
                              {userProfileImg ? (
                                <React.Fragment>
                                  <img
                                    src={userProfileImg} // Dynamic URL
                                    alt="user-img"
                                    className="img-thumbnail h-100 w-100 rounded-circle"
                                    onLoad={() => setProfileImageLoading(false)} // Hide loader when the image is loaded
                                    onError={() =>
                                      setProfileImageLoading(false)
                                    } // Hide loader if there's an error loading the image
                                  />
                                  {profileImageLoading && (
                                    <Loader
                                      size={24}
                                      className="position-absolute top-50 start-50 translate-middle"
                                    />
                                  )}
                                </React.Fragment>
                              ) : (
                                <img
                                  src={userDummy}
                                  alt="user-img"
                                  className="img-thumbnail rounded-circle"
                                />
                              )}
                            </div>
                          </div>
                          {username && suburbWithState ? (
                            <Col className="d-flex flex-column justify-content-between py-2">
                              <div>
                                <h3 className="mb-1">{username}</h3>
                                <div className="text-gray d-flex mt-3 align-items-center">
                                  <i className="ri-map-pin-line me-1 fs-15" />
                                  <span>{suburbWithState}</span>
                                </div>
                              </div>
                              <div className="hstack gap-2"></div>
                            </Col>
                          ) : (
                            <Col className="d-flex flex-column justify-content-between py-2">
                              <div>
                                <h3 className="card-title placeholder-glow">
                                  <span className="placeholder col-2"></span>
                                </h3>
                                <div className="text-gray d-flex mt-3 align-items-center">
                                  {/* <i className="ri-map-pin-line me-1 fs-15" /> */}
                                  <span className="placeholder col-1"></span>
                                </div>
                              </div>
                              <div className="hstack gap-2"></div>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xl={12}>
                  <Card className="mb-2">
                    <CardBody>
                      <h3 className="">Career Insights</h3>
                      <div className="my-4">
                        <p className="fs-16">
                          Welcome to Career Insights, a dedicated space within
                          Get Rostered designed to empower your career and
                          personal growth journey. The questions here are
                          crafted to understand your unique aspirations, skills,
                          and well-being. Rest assured, your responses are
                          completely confidential and won't be shared with
                          anyone.
                        </p>
                        <br></br>
                        <p className="fs-16">
                          They will solely be used to fuel our AI and machine
                          learning algorithms, ensuring you receive personalised
                          job recommendations, skill development tips, and
                          career advice tailored just for you. Let's embark on
                          this journey of discovery and growth together!
                        </p>
                      </div>
                      <hr />

                      {questionsAndAnswers?.data?.workforce_questions?.map(
                        (category, cIndex, categories) => (
                          <div key={category.category} className="mb-5">
                            <h3
                              key={category.category}
                              className="mb-4 text-uppercase"
                            >
                              {category.category}
                            </h3>
                            {category.questions?.map((question, qIndex) => {
                              const answer =
                                questionsAndAnswers?.data?.workforce_answers?.find(
                                  (a) => a.question_id === question.id
                                );

                              const isLastCategory =
                                cIndex === categories.length - 1;
                              const isLastQuestion =
                                qIndex === category.questions.length - 1;

                              return (
                                <div key={question.id} className="mb-3">
                                  <div className="d-flex justify-content-between">
                                    <h5 className="mb-2">
                                      {question.question}
                                    </h5>
                                    <i
                                      className={`${
                                        answer?.response_text
                                          ? "ri-edit-line text-primary fs-22"
                                          : ""
                                      }`}
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        t_Questions(
                                          category.category,
                                          question.id
                                        )
                                      }
                                    />
                                  </div>
                                  {console.log(questionStates)}
                                  <Collapse
                                    isOpen={
                                      !questionStates[category.category]?.[
                                        question.id
                                      ]
                                    }
                                    id={`view_collapse_${category.category}_${question.id}`}
                                  >
                                    <Row>
                                      <Col md={12}>
                                        <div className="mb-3">
                                          {answer ? (
                                            <div className="hstack">
                                              <i className="ri-checkbox-circle-fill text-success me-2  fs-20"></i>
                                              <p className="fs-16 text-muted">
                                                {answer?.response_text}{" "}
                                              </p>
                                            </div>
                                          ) : (
                                            <button
                                              className="btn btn-outline-info"
                                              onClick={() =>
                                                t_Questions(
                                                  category.category,
                                                  question.id
                                                )
                                              }
                                            >
                                              Add an answer
                                            </button>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>
                                  </Collapse>
                                  <Collapse
                                    isOpen={
                                      questionStates[category.category]?.[
                                        question.id
                                      ]
                                    }
                                    id={`edit_collapse_${category.category}_${question.id}`}
                                  >
                                    {console.log(answers)}
                                    <Row>
                                      <Col md={12}>
                                        <div className="mb-3">
                                          <span
                                            className="border border-1 px-2 pv-1 rounded-4 hstack d-inline-flex mb-1 cursor-pointer"
                                            onClick={() => tog_positionBottom()}
                                          >
                                            <i className="ri-magic-fill text-primary fs-18 me-1"></i>
                                            <p>Suggest a response</p>
                                          </span>
                                          <p className="d-block">
                                            Edit Answer:
                                          </p>
                                          <textarea
                                            className={`form-control mt-1`}
                                            type="text"
                                            cols={5}
                                            rows={5}
                                            value={
                                              (answers &&
                                                answers?.find(
                                                  (a) =>
                                                    a.question_id ===
                                                    question.id
                                                )?.response_text) ||
                                              ""
                                            }
                                            onChange={(e) =>
                                              handleInputChange(
                                                question.id,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={6}>
                                        <div className="hstack gap-4 mt-2">
                                          <button
                                            type="button"
                                            className="btn btn-soft-danger"
                                            onClick={() =>
                                              t_Questions(
                                                category.category,
                                                question.id
                                              )
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-soft-success"
                                            onClick={() =>
                                              save_Answers(
                                                category.category,
                                                question.id
                                              )
                                            }
                                          >
                                            Update
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Collapse>
                                  {!isLastCategory && isLastQuestion && (
                                    <div className="border border-dashed mt-3"></div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          <Modal
            size="lg"
            id="bottomModal"
            isOpen={modal_positionBottom}
            toggle={() => {
              tog_positionBottom();
            }}
            className="modal-dialog-bottom"
          >
            <Card className="mb-0">
              <CardBody>
                <h5 className="mb-2">
                  Enter the text and keyword that you want AI to use to give you
                  a response
                </h5>
                <textarea
                  className={`form-control mt-1`}
                  type="text"
                  cols={5}
                  rows={5}
                />
                <div className="d-flex justify-content-end mt-2">
                  <button type="button" className="btn btn-soft-success">
                    Use Response
                  </button>
                </div>
              </CardBody>
            </Card>
          </Modal>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CareerInsights;
