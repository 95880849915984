import React, { useState } from "react";
import { Navigate } from "react-router-dom";

// <-----------------------------ROSTER------------------------------->
//OTP
import TwoSVerify from "../pages/Authentication/BasicTwosVerify";
//Password Reset
import PasswReset from "../pages/Authentication/BasicPasswReset";
//Registeration 
import WorkForceSetup from "../pages/Authentication/WorkForceSetup";
// Directory
import Directory from "../pages/Directory/Index";
// Job Roster
import JobRoster from "../pages/JobRoster/Index";
// Invitations Sent
import InvitationsSent from "../pages/InvitationsSent/Index";
// Job Search 
import JobSearch from "../pages/JobSearch/Index";
// Applied Jobs
import AppliedJobs from "../pages/AppliedJob/Index";

import JobOffer from "../pages/JobOffers";
// Talent Pool
import TalentPool from "../pages/TalentPool/Index";
// Current Contracts
import CareerInsights from "../pages/CareerInsights/Index";
// Past Contracts
import PastContracts from "../pages/PastContracts/Index";
// Pending Invitations
import PendingInvitations from "../pages/PendingInvitations/Index";
// Overview
import Overview from "../pages/Overview/Index";
// Activity 
import Activity from "../pages/Activity/Index";
//Time Clock
import Timesheets from "../pages/Timesheets/Index";
// My Unavailability
import MyUnavailability from "../pages/MyUnavailability/Index";
// Chat
import Chat from "../pages/Chat/Index";
//Text Connect
import TextConnect from "../pages/TextConnect/Index";
// Bussiness Settings
import BusinessSettings from "../pages/BusinessSettings/Index";
import BusinessSettingsTabControl from "../pages/BusinessSettings/TabComponents/SettingsTabControl";
//sites
import Sites from "../pages/Sites/Index";
import AddSite from "../pages/Sites/AddSite";
import EditSite from "../pages/Sites/EditSite";
import SiteTabControl from "../pages/Sites/TabComponents/SettingsTabControl";

// <-----------------------------ROSTER------------------------------->


//dashboard
import Dashboard from "../pages/Dashboard/Index";

//Reporting
import LeadReport from "../pages/Reporting/LeadReport";
import OperationsReport from "../pages/Reporting/OperationsReport";
import VehicleCheck from "../pages/Reporting/VehicleCheck";
import SalesPipeline from "../pages/Reporting/SalesPipeline";

//Settings
import Settings from "../pages/Settings/Settings";
import PersonalisationDetail from "../pages/Settings/TabPages/PersonalisationDetail";

import { PagePersonalisation } from '../pages/Settings/TabPages/PagePersonalisation';
import SettingsTabControl from "../pages/Settings/TabComponents/SettingsTabControl";

// Email box
import BasicAction from "../pages/Email/EmailTemplates/BasicAction";
import EcommerceAction from "../pages/Email/EmailTemplates/EcommerceAction";

//AuthenticationInner pages
import BasicSignIn from "../pages/AuthenticationInner/Login/BasicSignIn";
import CoverSignIn from "../pages/AuthenticationInner/Login/CoverSignIn";
import BasicSignUp from "../pages/AuthenticationInner/Register/BasicSignUp";
import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp";
import BasicPasswReset from "../pages/AuthenticationInner/PasswordReset/BasicPasswReset";

import CoverPasswReset from "../pages/AuthenticationInner/PasswordReset/CoverPasswReset";
import BasicLockScreen from "../pages/AuthenticationInner/LockScreen/BasicLockScr";
import CoverLockScreen from "../pages/AuthenticationInner/LockScreen/CoverLockScr";
import BasicLogout from "../pages/AuthenticationInner/Logout/BasicLogout";
import CoverLogout from "../pages/AuthenticationInner/Logout/CoverLogout";
import BasicSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg";
import CoverSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg";
import BasicTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify";
import CoverTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Cover404 from "../pages/AuthenticationInner/Errors/Cover404";
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";

import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//login
import Login from "../pages/Authentication/Login";
import AppleLogin from "../pages/Authentication/AppleLogin";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import RedirectionPage from "../pages/RedirectionPage";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";
import { get_cookie } from "../helpers/get_cookie";


// Checkout 

import Checkout from "../pages/Checkout/checkout";


// import Others from "../pages/Others/Index";

// SupplyChain

import ErrorPage from "../pages/ErrorPage";
import ForgetPassword from "../pages/Authentication/ForgetPassword";
import MyProfile from "../pages/MyProfile/Index";



const authProtectedRoutes = [
  // ----------------------------ROSTER-----------------------------------
  //Registeration
  { path: "/workforce-setup", component: <WorkForceSetup /> },
  // Dashboard
  { path: "/directory", component: <Directory /> },
  //Job Roster
  { path: "/jobroster", component: <JobRoster /> },

  // Invitations Sent
  { path: "/invitation", component: <InvitationsSent /> },
  // Search Workforce
  { path: "/jobsearch", component: <JobSearch /> },
  // Job Postings
  { path: "/applied-jobs", component: <AppliedJobs /> },

  { path: "/Job_Offers", component: <JobOffer /> },
  { path: "/Job_Offers/:id", component: <JobOffer /> },

  // Talent Pool
  { path: "/talent-pool", component: <TalentPool /> },
  // Current Contracts
  { path: "/career-insights", component: <CareerInsights /> },
  // Past Contracts
  { path: "/past-contracts", component: <PastContracts /> },
  // Pending Invitations
  { path: "/pending-invitation", component: <PendingInvitations /> },
  // Overview
  { path: "/overview", component: <Overview /> },
  // Activity 
  { path: "/activity", component: <Activity /> },
  { path: "/activity/:id", component: <Activity /> },
  // Time CLock
  { path: "/timesheets", component: <Timesheets /> },
  // Quick Tasks
  { path: "/myunavailability", component: <MyUnavailability /> },
  // Chat
  { path: "/chat", component: <Chat /> },
  // Text Connect
  { path: "/textconnect", component: <TextConnect /> },
  // BussinessSettings 
  { path: "/business_settings", component: <BusinessSettings /> },
  { path: "/businesssettingstabcontent", component: <BusinessSettingsTabControl /> },
  { path: "/business_settings/:activeTab", component: <BusinessSettings /> },
  //Sites
  { path: "/sites", component: <Sites /> },
  { path: "/AddSite", component: <AddSite /> },
  { path: "/EditSite", component: <EditSite /> },
  // ----------------------------ROSTER-----------------------------------

  // {path:"/stocklocations", component:<StockLocations/>},
  // {path:"/stockwarehouses", component:<StockWarehouses/>},
  // {path:"/unitsofmeasure", component:<UnitsOfMeasure/>},
  // {path:"/uomconversions", component:<UOMConversions/>},


  //Dashboard
  { path: "/dashboard", component: <Dashboard /> },

  //reporting
  { path: "/salespipeline", component: <SalesPipeline /> },
  { path: "/operationsreport", component: <OperationsReport /> },
  { path: "/leadreport", component: <LeadReport /> },
  { path: "/dailyvehiclecheck", component: <VehicleCheck /> },


  //Settings
  { path: "/settings/:activeTab", component: <Settings /> },
  { path: "/personalisationdetail/:id", component: <PersonalisationDetail /> },
  // { path: "/support", component: <Support /> },
  { path: "/pagepersonalisation", component: <PagePersonalisation /> },
  { path: "/settingstabcontent", component: <SettingsTabControl /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // My Profile
  { path: "/user-profile", component: <MyProfile /> },

  // Other dummy test
  // {path:"/others", component: <Others />},


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: (get_cookie('workauthUser')) ? <Navigate to="/user-profile" /> : <Navigate to="/login" />,
  },
  //  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/applelogin", component: <AppleLogin/>},
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "/twoStepVerify", component: <TwoSVerify /> },
  { path: "/pass-reset", component: <PasswReset /> },
  { path: "/reset-password", component: <ResetPassword /> },
  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: <BasicSignIn /> },
  { path: "/auth-signin-cover", component: <CoverSignIn /> },
  { path: "/auth-signup-basic", component: <BasicSignUp /> },
  { path: "/auth-signup-cover", component: <CoverSignUp /> },
  { path: "/auth-pass-reset-basic", component: <BasicPasswReset /> },
  { path: "/auth-pass-reset-cover", component: <CoverPasswReset /> },
  { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  { path: "/auth-lockscreen-cover", component: <CoverLockScreen /> },
  { path: "/auth-logout-basic", component: <BasicLogout /> },
  { path: "/auth-logout-cover", component: <CoverLogout /> },
  { path: "/auth-success-msg-basic", component: <BasicSuccessMsg /> },
  { path: "/auth-success-msg-cover", component: <CoverSuccessMsg /> },
  { path: "/auth-twostep-basic", component: <BasicTwosVerify /> },
  { path: "/auth-twostep-cover", component: <CoverTwosVerify /> },
  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-cover", component: <Cover404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },
  { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  { path: "/auth-pass-change-cover", component: <CoverPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },

  //Checkout
  { path: "/pay-now-inv/:encrypt", component: <Checkout /> },
  { path: "/pay-now-inv/:status/:encrypt", component: <Checkout /> },

  //edit site 

  { path: "/sitetabcontent", component: <SiteTabControl /> },
  { path: "/editsite/:activeTab/:id", component: <EditSite /> },
  { path: "/notifications", component: <RedirectionPage />}

];

export { authProtectedRoutes, publicRoutes };
