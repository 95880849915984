
export const SettingsTabPages = {
    ResetPassword: {
        id: 0,
        name:"reset_password",
        displayText: 'Reset Password',
        iconClass: 'cog'
    },
    DeleteAccount: {
        id: 1,
        name:"delete_account",
        displayText: 'Delete Account',
        iconClass: 'cog'
    },
    TermsOfUse: {
        id: 2,
        name:"terms_conditions",
        displayText: 'Terms & Conditions',
        iconClass: 'cog'
    },
    PrivacyPolicy:{
        id: 3,
        name:"privacy_policy",
        displayText: 'Privacy Policy',
        iconClass: 'cog'
    }
}

