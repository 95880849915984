import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { add_cookie } from "../../helpers/get_cookie";

const AppleLogin = (props) => {
    const navigate = useNavigate();
    const [response, setResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        axios.get('/get-apple-login-response')
        .then((data) => data.response)
        .then((response) => {
            console.log(response);
            if (response.status == "200" && response.message != "User registered successfully"
            ){
              add_cookie(response, false);
              navigate("/overview");
            } else if (response.message === "User is not confirmed.") {
              axios.post("/resend-otp", { email: response.email }).then((res) => {});
              toast.warning(
                "User is not verified!. Redirecting to verification page...",
                { autoClose: 2000 }
              );
              setTimeout(() => {
                navigate("/twoStepVerify", { state: { email: response.email } });
                setIsLoading(false);
              }, 3000);
            } else if (response.message === "Incorrect username or password.") {
              toast.error("Incorrect user email or password!");
              setIsLoading(false);
            } else if (response.message === "User registered successfully") {
              navigate("/twoStepVerify", { state: { email: response.email } });
              setIsLoading(false);
            }
        })
        .catch((error) => {
            console.error('Error fetching session data:', error);
        });
    }, []);

    return (
        <div>
        {response ? (
            <div>Login Successful: {response}</div>
        ) : (
            <div>Processing Login...</div>
        )}
        </div>
    );
};

export default withRouter(AppleLogin);