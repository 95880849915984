// import React from 'react'
import { Card, CardBody, Nav, NavItem, NavLink } from 'reactstrap';
import {SettingsTabPages } from '../Constants/SettingsConstants';
import TabControlNavItem from '../../../Components/Common/TabControlNavItem';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import React, { useEffect, useState } from "react";


export default function SettingsTabControl({ activeTabSetter, activeTab }) {
    const history = useNavigate();

    function clickHandler(navObject) {
      console.log(navObject);
        if(navObject.displayText == "General"){
            history("/business_settings/general");
          }else if(navObject.displayText == "People"){
            history("/business_settings/people");
          }else if(navObject.displayText == "Roster"){
            history("/business_settings/roster");
          }else if(navObject.displayText == "Timesheets"){
            history("/business_settings/timesheets");
          }else if(navObject.displayText == "Leave"){
            history("/business_settings/leave");
          }
        activeTabSetter(navObject);
    }

 
    return (
        <Card>
            <CardBody>
                <Nav pills vertical>
{console.log(Object.keys(SettingsTabPages),"helo")}
                    {
                         Object.keys(SettingsTabPages).map(tabKey =>
                           <TabControlNavItem navObject={SettingsTabPages[tabKey]} onClickCallback={clickHandler} key={tabKey.id} activeTab={activeTab} />
                         )
                        
                    }
                </Nav>
            </CardBody>
        </Card>


    )
}
