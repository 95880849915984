import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { get_cookie } from "../helpers/get_cookie";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isCustomer, setIsCustomer] = useState(false);
  const [isJobs, setIsJobs] = useState(false);
  const [isReporting, setIsReporting] = useState(false);
  const [isSupplyChain, setIsSupplyChain] = useState(false);
  const [isAuth, setIsAuth] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  useEffect(() => {
    const api_token = JSON.parse(get_cookie('workauthUser'));
    console.log(api_token.success)
    if (api_token.success) {
      const headers = {
        Authorization: `Bearer ${api_token.token}` // Assuming the token is stored in the 'token' property
      };
      axios.get("/api/SidebarCount", { headers }).then((res) => {
        console.log("res", res)
      })
    }


  }, [
    history,
    iscurrentState,
    isCustomer,
    isJobs,
    isReporting,
    isSupplyChain,
    isAuth,
  ]);
  const menuItems = [

    //Overview
    {
      id: "overview",
      label: "Overview",
      icon: "bx bx-grid-alt",
      link: "/overview",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Overview");
      },
    },

    //Activity
    {
      id: "activity",
      label: "Activity",
      icon: "ri-menu-2-line",
      link: "/activity",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Activity");
      },
    },
    //Operations
    {
      label: "Operations",
      isHeader: true,
    },
    //Time Clock
    {
      id: "timesheets",
      label: "Timesheets",
      icon: "bx bx-stopwatch",
      link: "/timesheets",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("timesheets");
      },
    },
    //Quick Tasks
    {
      id: "myunavailability",
      label: "My Unavailability",
      icon: "bx bx-task",
      link: "/myunavailability",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("MyUnavailability");
      },
    },

    //Talent Hub
    {
      label: "Opportunities",
      isHeader: true,
    },

    //Search  Workforce
    {
      id: "search",
      label: "Job Search",
      icon: "ri-survey-line",
      link: "/jobsearch",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("JobSearch");
      },
    },
    {
      id: "JobOffer",
      label: "Job Offers",
      icon: "ri-account-box-line",
      link: "/Job_Offers",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("JobOffers");
      },
    },
    //Job Postings
    {
      id: "appliedjobs",
      label: "Applied Jobs",
      icon: "ri-account-box-line",
      link: "/applied-jobs",
      // left_icon: true,
      click: function (e) {
        e.preventDefault();
        setIscurrentState("AppliedJobs");
      },
    },
    {
      label: "Communication",
      isHeader: true,
    },
    {
      id: "chats",
      label: "Chat",
      icon: "ri-survey-line",
      link: "/chat",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("chat");
      },
    },
    //Engagements
    {
      label: "Profile",
      isHeader: true,
    },
    //My Profile
    {
      id: "profile",
      label: "My Profile",
      icon: "ri-menu-2-line",
      link: "/user-profile",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("MyProfile");
      },
    },
    {
      id: "careerinsights",
      label: "Career Insights",
      icon: "bx bx-file",
      link: "/career-insights",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("careerinsights");
      },
    },
    {
      id: "profile",
      label: "Settings",
      icon: "ri-settings-3-line",
      link: "/settings/organisation-settings",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("MyProfile");
      },
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
