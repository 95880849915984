import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
import SettingsTabControl from './TabComponents/SettingsTabControl';
import SettingsTabContent from './TabComponents/SettingsTabContent';
import { SettingsTabPages } from './Constants/SettingsConstants';
import { useParams } from 'react-router-dom';


// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';
function Settings() {
  document.title = "Settings | Get Roster";
  const params = useParams();
  const tab = params.activeTab;
  const [activeTab, setActiveTab] = useState(SettingsTabPages.ResetPassword);

  //Pagepersonalsation Headings
  const getPageTitle = () => {
    switch (activeTab) {
      case SettingsTabPages.ResetPassword:
        return 'Reset Password';
      case SettingsTabPages.DeleteAccount:
        return 'Delete Account';
      case SettingsTabPages.TermsOfUse:
        return 'Terms & Conditions';
      case SettingsTabPages.PrivacyPolicy:
        return 'Privacy policy';  
      default:
        return '';
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={getPageTitle()} pageTitle="" />
        <Row>
          <Col md="3">
            <SettingsTabControl activeTabSetter={setActiveTab} activeTab={activeTab} />
          </Col>
          {activeTab ? (
            <Col md="9">
              <SettingsTabContent activeTab={activeTab} />
            </Col>) : (<Col md="9">
              <Card className='p-3 text-bold'> Permission Not Granted.</Card>
            </Col>)}

        </Row>
      </Container>
    </div>
  );
}
export default Settings
