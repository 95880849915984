import React from "react";
import "react-image-crop/dist/ReactCrop.css";
//import Scss
import "./assets/scss/themes.scss";

import "./assets/scss/custom.scss";

//imoprt Route
import Route from "./Routes";

// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper";

// Fake Backend
// import fakeBackend from "./helpers/AuthType/fakeBackend";
import axios from "axios";
import { get_cookie } from "./helpers/get_cookie";

// Activating fake backend
// fakeBackend();
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
      .then(function(registration) {
          console.log('Service Worker registered with scope:', registration.scope);
      }).catch(function(error) {
          console.error('Service Worker registration failed:', error);
      });
}

function App() {
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
