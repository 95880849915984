import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
  Button,
  Spinner,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { get_cookie } from "../../helpers/get_cookie";
import axios from "axios";
import DOMPurify from "dompurify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as moment from "moment";

const AppliedJobDetail = ({ jobId,toggle }) => {
  const user_id = JSON.parse(get_cookie("workauthUser")).user_id;

  //constants to store the main data
  const [jobData, setJobData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isApplying, setIsApplying] = useState(false);
  const [message, setMessage] = useState("");
  const [modal_assignModal, setmodal_assignModal] = useState(false);

  // Sanitize the HTML content
  const sanitizedDescription = DOMPurify.sanitize(jobData?.description || "");

  function tog_assignModal() {
    setmodal_assignModal(!modal_assignModal);
  }

  const loadData = () => {
    setIsLoading(true);
    let jobDetail;
    if (user_id != null) {
      jobDetail = {
        job_id: jobId,
        user_id: user_id,
      };
      axios
        .post("/job-description-data", jobDetail)
        .then((res) => {
          if (res.message === "Success") {
            setJobData(res.Job_search);
            setIsLoading(false);
          }
        })
        .then((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  const applyJob = (message) => {
    setIsApplying(true);
    const formData = {
      user_id: user_id,
      business_id: jobData?.business_id,
      job_vacancy_id: jobId,
      message: message,
    };
    axios
      .post("/send-job-application", formData)
      .then((res) => {
        if (res.status == "Success") {
          toast.success(res.message);
          setmodal_assignModal(!modal_assignModal);
          loadData();
          setIsApplying(false);
        } else {
          setIsApplying(false);
          toast.error(res.error);
        }
      })
      .catch((err) => {
        setIsApplying(false);
        console.log(err);
      });
  };

  document.title = "Job Detail | Get Rostered Workforce";
  return (
    <React.Fragment>
      <div className="pt-4">
        <Container fluid>
          {!isLoading ? (
            <>
              <div className="mb-2 mb-lg-0 pb-lg-4 profile-wrapper ">
                <Row className="g-4 ">
                  <Col>
                    <div className="px-2">
                      <div className="hstack justify-content-between">
                      <h4 className="text-primary mb-1">
                        {jobData?.job_title}
                      </h4>
                      <div className="text-end"><button onClick={toggle} type="botton" className="btn btn-dark">Close</button></div>

                      </div>

                      <div className="hstack  h6 gap-2">
                        <div className="me-2 hstack">
                          <i className="ri-map-pin-line me-1  fs-14 text-danger align-middle"></i>
                          <p className="fs-14 fw-normal text-muted">
                            {jobData?.location ? jobData?.location : "--"}
                          </p>
                        </div>
                      </div>
                      <div className="hstack gap-2">
                      <p className="">Job Published: <span className="text-muted">{moment(jobData?.posted_date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")}</span></p>
                      <p className="">Hourly Rate : <span className="text-muted">{jobData?.rate}</span></p>
                      </div>
                      <div className="mt-4">
                        <p
                        style={{lineHeight:'1.5rem'}}
                          dangerouslySetInnerHTML={{
                            __html: sanitizedDescription,
                          }}
                        ></p>
                      </div>
                      <div className="pt-3">
                        <h5>Skills</h5>
                        <div className="my-2">
                          {jobData?.job_skills &&
                          jobData?.job_skills.length > 0 ? (
                            jobData?.job_skills?.map((skill, index) => (
                              <span
                                key={index}
                                className="badge badge-soft-info fs-12 me-2 p-2"
                              >
                                {skill}
                              </span>
                            ))
                          ) : (
                            <Badge className="bg-soft-dark fs-12 me-2">
                              None
                            </Badge>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mt-0 pt-4 px-2">
                      <h5>About the Business</h5>
                      <div className="hstack gap-4 align-items-center mt-3">
                        <h6 className="mt-0 fw-semibold">{jobData?.business_name} </h6>
                        <div className="hstack gap-2 ">
                          <i className="text-danger fs-18 bx bx-map-pin"></i>
                          <h6 className="mt-0 text-muted fw-normal">{jobData?.suburb}</h6>
                        </div>
                      </div>
                      <div
                        className="mt-3"
                        style={{ maxHeight: "200px"}}
                      >
                        {jobData?.about_the_business}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <div className="mb-2 mb-lg-0 pb-lg-4 profile-wrapper ">
                <Row className="g-4 ">
                  <Col>
                    <div className="px-2">
                      <Row className="text-primary mb-1 placeholder-glow hstack justify-content-between">
                        <Col xs={8}>
                          <span className="placeholder col-4 bg-dark"></span>
                        </Col>
                      </Row>

                      <div className="hstack  h6 gap-2 placeholder-glow">
                        <span className="placeholder col-2 bg-dark"></span>
                        <div className="placeholder-glow col-4">
                          <span>
                            <b>Hourly Rate :</b>
                          </span>{" "}
                          <span className="placeholder col-6 bg-dark"></span>
                        </div>
                      </div>
                      <p className="text-muted placeholder-glow">
                        <span className="placeholder col-1 bg-dark"></span>
                      </p>
                      <div className="mt-4">
                        <p className=" placeholder-glow">
                          <span className="placeholder col-6 bg-dark"></span>
                          <span className="placeholder col-8 bg-dark"></span>
                          <span className="placeholder col-8 bg-dark"></span>
                          <span className="placeholder col-8 bg-dark"></span>
                          <span className="placeholder col-8 bg-dark"></span>
                          <span className="placeholder col-8 bg-dark"></span>
                        </p>
                      </div>
                      <div className="pt-3">
                        <h5>Skills</h5>
                        <div className="my-2 placeholder-glow">
                          <span className="placeholder col-1 bg-dark me-2"></span>
                          <span className="placeholder col-1 bg-dark"></span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mt-0 pt-4 px-2">
                      <h5>About the Business</h5>
                      <div className="hstack gap-4 align-items-center mt-2 placeholder-glow">
                        <span className="placeholder col-2 bg-dark"></span>{" "}
                        <span className="placeholder col-2 bg-dark"></span>{" "}
                      </div>
                      <div
                        className="mt-3 placeholder-glow"
                        style={{ maxHeight: "200px", overflow: "scroll" }}
                      >
                        <span className="placeholder col-6 bg-dark"></span>
                        <span className="placeholder col-7 bg-dark"></span>
                        <span className="placeholder col-7 bg-dark"></span>
                        <span className="placeholder col-7 bg-dark"></span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Container>
        <Modal
          size="md"
          isOpen={modal_assignModal}
          toggle={() => {
            tog_assignModal();
          }}
          centered
        >
          <ModalHeader className=" text-center m-auto">
            <h5 className="text-center fs-18">
              Are you sure you want to apply for this job?
            </h5>
            <p className=" fw-semibold fs-13 my-1">
              Your application will be submitted to the employer for review
            </p>
            <p className=" fw-semibold fs-13 my-1">Confirm to proceed</p>

          </ModalHeader>
          <ModalBody className="px-4 py-2">
            <label
              className=" text-start form-label fs-14"
              style={{ textAlign: "start" }}
            >
              Cover Letter
            </label>
            <textarea
              type="text"
              className="form-control"
              placeholder="Enter your application message here"
              value={message}
              rows={10}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            <Row className="my-4 d-flex justify-content-center">
              <Col className=" hstack gap-3 p-0 justify-content-center">
                <Button className="btn btn-danger" onClick={tog_assignModal}>
                  Cancel
                </Button>
                <Button
                  className="btn btn-success"
                  disabled={isApplying}
                  onClick={() => applyJob(message)}
                >
                  {isApplying ? (
                    <>
                      <Spinner size="sm" color="light" className="me-2" />{" "}
                      Applying...
                    </>
                  ) : (
                    "Apply for the Job"
                  )}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
       
      </div>
      <ToastContainer closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default AppliedJobDetail;
