import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Button, Spinner } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useNavigate } from 'react-router-dom';
//import images
import logoLight from "../../assets/images/logo-light.png";
import logoRoster from "../../assets/images/logo-roster.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
const BasicTwosVerify = () => {
    document.title = "Two Step Verification | Get Rostered";
    const location = useLocation();
    const emailFromRegistration = location.state.email;
    const [isResend, setIsResend] = useState(false);
    const [otpValues, setOtpValues] = useState(['', '', '', '', '', '']);
    const [email, setEmail] = useState('');
    const [isInCorrect, setIsInCorrect] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // You can get the email from the previous screen or wherever it is stored
    const navigate = useNavigate();

    const getInputElement = (index) => {
        return document.getElementById('digit' + index + '-input');
    }

    const moveToNext = (index) => {
        if (getInputElement(index).value.length === 1) {
            if (index !== 6) {
                getInputElement(index + 1).focus();
            } else {
                getInputElement(index).blur();
                // Submit code
                console.log('submit code');
            }
        }
    }
    const handleKeyUp = (index, value) => {
        // Update the OTP values based on the input
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value;
        setOtpValues(newOtpValues);

        // Move focus to the next input field when a digit is entered
        if (value && index < otpValues.length - 1) {
            const nextInput = document.getElementById(`digit${index + 2}-input`);
            if (nextInput) {
                nextInput.focus();
            }
        }
    };
    const handleConfirm = async () => {
        setIsLoading(true);
        const verificationCode = otpValues.join('');

        // Make the API call
        try {
            await axios.post('/confirm-user', {
                email: emailFromRegistration,
                verification_code: verificationCode,

            }).then((response) => {

                if (response.status === "success") {
                    toast.success("Registeration Successful. Redirecting to login page..", { autoClose: 2000 });
                    setTimeout(() => {
                        navigate('/login')
                    }, 3000)
                    setIsLoading(false);
                    navigate('/login')
                }
                else {
                    setIsInCorrect(true)
                    setIsLoading(false);

                }
            })

            // Handle the response as needed
            // console.log('API Response:', response.data);
        } catch (error) {
            // Handle errors, e.g., display an error message
            console.error('Error confirming user:', error);
            setIsLoading(false);
        }
    };
    return (
        <React.Fragment>
            <div className="auth-page-wrapper">
                <ParticlesAuth>
                    <div className="auth-page-content">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                                        <div>
                                            <Link to="/dashboard" className="d-inline-block auth-logo">
                                                <img src={logoRoster} alt="" height="35" />
                                            </Link>
                                        </div>
                                        <p className="mt-3 fs-15 fw-medium"></p>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    <Card className="">
                                        <CardBody className="p-4">
                                            <div className="mb-4">
                                                <div className="avatar-lg mx-auto">
                                                    <div className="avatar-title bg-light text-primary display-5 rounded-circle">
                                                        <i className="ri-mail-line"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-2 mt-4">
                                                <div className="text-muted text-center mb-4 mx-lg-3">
                                                    {!isResend ? (
                                                        <>
                                                            <h4 className="">Thank you for registering!</h4>
                                                            <p className='mt-2'> We've sent a One-Time Password (OTP) to your email.
                                                                <br />Please check both your Inbox and Spam folders for the OTP.</p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h4 className="">OTP has been Resent to your email</h4>
                                                            <p className='mt-2'>Please check your email's Inbox and Spam folders.
                                                            </p>
                                                        </>
                                                    )}

                                                </div>

                                                <form>
                                                    <Row>
                                                        {[1, 2, 3, 4, 5, 6].map((index) => (
                                                            <Col className="col-2" key={index}>
                                                                <div className="mb-3">
                                                                    <label htmlFor={`digit${index}-input`} className="visually-hidden">
                                                                        Digit {index}
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        value={otpValues[index - 1]}
                                                                        className="form-control bg-light border-light text-center"
                                                                        maxLength="1"
                                                                        id={`digit${index}-input`}
                                                                        onChange={(e) => handleKeyUp(index - 1, e.target.value)}
                                                                        // onKeyUp={(e) => handleKeyUp(index - 1, e.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        ))}
                                                    </Row>

                                                </form>
                                                {isInCorrect && <div className='text-danger'>The OTP you entered is incorrect. Please enter the correct OTP.</div>}

                                                <div className="mt-3">
                                                    <button className="w-100 btn text-white btn-signup" disabled={isLoading ? true : false} onClick={handleConfirm}>
                                                    {isLoading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}Confirm</button>
                                                </div>


                                            </div>
                                        </CardBody>
                                    </Card>
                                    <div className="mt-4 d-flex justify-content-between ">
                                        <Link to="/register" >Back to Register</Link>
                                        <t className="mb-0   ">Didn't receive a code? &nbsp;
                                            <span className="fw-semibold text-primary text-decoration-underline" style={{ textDecoration: "underline", cursor: "pointer" }}
                                                onClick={() => {
                                                    setIsResend(true);
                                                    axios.post('/resend-otp', { 'email': emailFromRegistration })
                                                        .then(res => {
                                                            console.log(res)
                                                        })
                                                        .then(err => {
                                                            console.log(err)
                                                        })
                                                }}>Resend</span> </t>
                                    </div>
                                </Col>
                            </Row>
                            <ToastContainer/>
                        </Container>
                    </div>
                </ParticlesAuth >

            </div>
        </React.Fragment >
    );
};

export default BasicTwosVerify;