import PrismCode from "../../../Components/Common/Prism";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, Alert, UncontrolledAlert, TabContent, TabPane, UncontrolledTooltip } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo, useRef  } from "react";
import { Button } from 'reactstrap';
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import "../../../assets/scss/pages/_settings.scss";
import {
  CardHeader,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback
} from "reactstrap";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../../Components/Common/Loader";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
//reloading the page
import { jsx } from '@emotion/react'
import { ClipLoader } from 'react-spinners';
import {api} from  "../../../config";
import { toast, ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom';

//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const Zone = () => {
   document.title="Zone | Roster";
    const params = useParams();
    const site_id = params.id
   const [isUpdating, setIsUpdating] = useState(false);
   const [isLoading, setisLoading] = useState(false);
    const [fieldSets, setFieldSets] = useState(1);
    const [fieldSetsZone, setFieldSetsZone] = useState(1);
    const [zone_data, setZonesData] = useState();
    const [name, setZoneName] = useState();
    const [description, setZoneDescription] = useState();
    const [id, setId] = useState();
    const loadzone = () => {
    setisLoading(true);
    axios.get(`/api/get-zone-data/${site_id}`)
      .then(response => {
        setZonesData(response.zones);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      })
  }
    //*backend response*
    useEffect(() => {
      loadzone();
    }, []);  
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState(zone_data?.map(() => ({})) ?? []);
    const [editingItemId, setEditingItemId] = useState(null);
    const handleEditClick = (id) => {
      setEditingItemId(id);
      setIsEditing(true);
      setEditedData((prevData) => {
        const index = prevData.findIndex(item => item.id === id);
        if (index !== -1) {
          const newData = [...prevData];
          newData[index] = { ...newData[index], ...zone_data.find(item => item.id === id) };
          return newData;
        }
        return prevData;
      });
    };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleInputChange = (e, index) => {
  const { name, value } = e.target;
  setEditedData((prevData) => {
    const newData = [...prevData];
    newData[index] = {
      ...newData[index],
      [name]: value,
    };
    return newData;
  });
};
const handleUpdateClick = (index) => {
  const updatedData = editedData[index];
    console.log(updatedData.zone_name);
    setisLoading(true);
        var formData = {
          "site_id":site_id,
          "id":editingItemId,
          "zone_name":updatedData.zone_name,
          "zone_description":updatedData.zone_description,
        };

    axios.post('/api/update-zone-data', formData)
      .then(response => {
        toast.success(response.success, { theme: "light" });
        setZonesData((prevData) => {
          const newData = [...prevData];
          newData[index] = { ...updatedData };
          return newData;
        });
          setIsEditing(false);
          loadzone();
      })
      .catch(error => {
        console.error(error);
        toast.error('Something went wrong...');
      })
      .finally(() => {
        setisLoading(false); //reloading the page
      });
};
  const handleSubmit = (event) => {
      //event.preventDefault();
        setisLoading(true);
          var formData = {
            "site_id" :site_id,
            "zone_name" : name,
            "zone_description" : description
          };
      axios.post('/api/update-zone-data', formData)
        .then(response => {
          toast.success(response.success, { theme: "light" });
          loadzone();
           setZoneName('');
          setZoneDescription('');
        })
        .catch(error => {
          console.error(error);
          toast.error('Something went wrong...');
        })
        .finally(() => {
          setisLoading(false); //reloading the page
        });
    }
    const handleDelete = (id) => {
      //event.preventDefault();
        setisLoading(true);
      axios.get(`/api/destroy-zone-data/${id}`)
        .then(response => {
          toast.success(response.success, { theme: "light" });
          loadzone();
        })
        .catch(error => {
          console.error(error);
          toast.error('Something went wrong...');
        })
        .finally(() => {
          setisLoading(false); //reloading the page
        });
    }

  return (
    <div> 
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center flex-wrap gap-2">
            <div className="flex-grow-1">
            <h5 className="mb-0 text-primary">Zone</h5>
            </div>
            <div className="flex-shrink-0">
            </div>
          </div>
        </CardHeader>
         <CardBody>
          <Col xxl={10}>
                <TabPane tabId={2}>
                          <div className="mt-4">
                            <div className="d-flex align-items-center mb-2">
                            </div>
                                    <div>
                                      <Row className="gy-3">
                                       <Col sm={3}>
                                          <div className="mb-3">
                                            <Input value={name} onChange={(e) => setZoneName(e.target.value)}
                                              type="text"
                                              className="form-control"
                                              id="billinginfo-firstName"
                                              placeholder="Zone Name"
                                            />
                                          </div>
                                        </Col>
                                        <Col sm={6}>
                                          <div className="mb-3">
                                            <Input value={description} onChange={(e) => setZoneDescription(e.target.value)}
                                              type="text"
                                              className="form-control"
                                              id="billinginfo-firstName"
                                              placeholder="Zone Description"
                                            />
                                          </div>
                                        </Col>
                                        <Col sm={1}>
                                          <div className="flex-shrink-0">
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-info mb-3"
                                      onClick={handleSubmit}
                                    >
                                      save
                                    </button>
                                  </div>
                                          </Col>
                                      </Row>
                                    </div>
                                
                          </div>
                </TabPane>
                  <Row className="gy-2 mb-2" id="candidate-list">
                    {zone_data?.map((item, key) => (
                      <Col xxl={12} key={key}>
                        <Card className="mb-0">
                          <CardBody>
                              {isEditing && editingItemId === item.id ? (
                                  <form>
                                  <Row>
                                  <Input
                                    type="hidden"
                                    name="id"
                                    value={editedData[key]?.id || item.id}
                                    onChange={(e) => handleInputChange(e, key)}
                                  />
                                  <Col xxl={5} md={5}>
                                    <Input
                                    type="text"
                                    name="zone_name"
                                    value={editedData[key]?.zone_name || item.zone_name}
                                    onChange={(e) => handleInputChange(e, key)}
                                  />
                                  </Col>
                                  <Col xxl={5} md={5}>
                                  <Input
                                    type="text"
                                    name="zone_description"
                                    value={editedData[key]?.zone_description || item.zone_description}
                                    onChange={(e) => handleInputChange(e, key)}
                                  />
                                  </Col>
                                  <Col xxl={1} md={1}>
                                    {/* Save and Cancel buttons */}
                                    <button className="btn btn" type="button" onClick={() => handleUpdateClick(key)}>
                                      Update
                                    </button>
                                    </Col>
                                    <Col xxl={1} md={1}>
                                    <button className="btn btn" type="button" onClick={handleCancelClick}>
                                      Cancel
                                    </button>
                                    </Col>
                                    </Row>
                                  </form>
                                ) : (
                              <Row>
                                <Col xxl={4} md={4}>
                                <div>
                                  {item.zone_name}
                                </div>
                              </Col>
                              <Col xxl={4} md={4}>
                              {item.zone_description}
                              </Col>
                              <Col xxl={1} md={1}>
                                <Link
                                  to="#"
                                  onClick={() => handleEditClick(item.id)}
                                  className="text-success"
                                >
                                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                  <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                  </UncontrolledTooltip>
                                </Link>
                                </Col>
                                <Col xxl={1} md={1}>
                                <Link
                                  to="#"
                                  onClick={() => handleDelete(item.id)}
                                  className="text-danger m-3"
                                  
                                >
                                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                  <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                  </UncontrolledTooltip>
                                </Link>
                                </Col>
                              </Row>
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                   ))} 
                  </Row>
          </Col>
          <ToastContainer closeButton={false} limit={1} theme="light" />
           {/* reloading the page */}
           {isUpdating && (
                <div className="spinner-overlay">
                  <ClipLoader css={override} size={35} color={'#123abc'} loading={true} />
                </div>
              )}
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  )
}
