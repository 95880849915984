import PrismCode from "../../Components/Common/Prism";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, Alert, UncontrolledAlert, TabContent, TabPane, UncontrolledTooltip, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import img13 from "../../assets/images/small/img-13.png";
import { Button } from 'reactstrap';
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../assets/scss/pages/_settings.scss";
import DeleteModal from "../../Components/Common/DeleteModal";
import {
  CardHeader,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback
} from "reactstrap";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "../../store/ecommerce/action";
//reloading the page
import { jsx } from '@emotion/react'
import { ClipLoader } from 'react-spinners';
import { api } from "../../config";


//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const Sites = () => {
  document.title = "Sites | Get Roster";
  const [modal, setModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setisLoading] = useState(false);


  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      tog_Company();
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const [rowsData, setRowsData] = useState([]);
  const addTableRows = () => {
    const rowsInput = {
      fullName: '',
      emailAddress: '',
      salary: ''
    }
    setRowsData([...rowsData, rowsInput])
  }

  const [contact, setContact] = useState([]);
  const dispatch = useDispatch();

  //react
  const isContactSuccess = true;
  const error = null;
  const [data, setData] = useState([]);
  const crmcontacts = data;

  const loadTable = () => {
    //setisLoading(true);
    axios.get('/sites')
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        //setisLoading(false);
      })
  }

  useEffect(() => {
    loadTable();
  }, []);


  //input field values
  const [company_name, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [contact_name, setContactName] = useState("");
  const [phone, setPhone] = useState("");
  const [sms_number, setSmsNumber] = useState("");
  const [address, setAddress] = useState("");
  const [abn, setAbn] = useState("");
  const [payment_terms, setPaymentTerms] = useState("");
  const [customer_sign_off_checklist, setCustomerSign] = useState("");
  const [customer_pre_job_checklist, setCustomerPre] = useState("");
  const [work_order_instructions, setWorkOrderInstructions] = useState("");
  const [pod_instructions, setPodInstructions] = useState("");
  const [defaultInterest, setDefaultInterest] = useState("N");
  const [activeInterest, setActiveInterest] = useState("N");
  const [imagesel, setImage] = useState("");
  const [id, setId] = useState("");
  const [shouldShowIdField, setShouldShowIdField] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [uploadedImage, setUploadedImage] = useState("");



  //checkbox code
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "default") {
      setDefaultInterest(checked ? "Y" : "N");
    } else if (name === "active") {
      setActiveInterest(checked ? "Y" : "N");
    }
  };
  //react
  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);


  const [isEdit, setIsEdit] = useState(false);
  // Delete Data
  const handleDeleteContact = () => {
    if (contact) {
      dispatch(onDeleteContact(contact._id));
      setDeleteModal(false);
    }
  };
  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };
  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal_large, setModal_Company] = useState(false);
  function tog_Company() {
    setModal_Company(!modal_large);
  }

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };


  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || '',
      company: '',
      designation: '',
      email: (contact && contact.email) || '',
      phone: (contact && contact.phone) || '',
      lead_score: (contact && contact.lead_score) || '',
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact 
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  // Update Data
  const handleContactClick = useCallback((arg) => {
    const contact = arg;

    setContact({
      _id: contact._id,
      // img: contact.img,
      name: contact.name,
      company: contact.company,
      email: contact.email,
      designation: contact.designation,
      phone: contact.phone,
      lead_score: contact.lead_score,
      last_contacted: contact.date,
      // time: contact.time,
      tags: contact.tags,
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  const [order, setOrder] = useState([]);



  const handleOrderClick = useCallback((arg) => {

    const order = arg;
    setOrder({
      _id: order._id,
      orderId: order.orderId,
      customer: order.customer,
      product: order.product,
      orderDate: order.orderDate,
      ordertime: order.ordertime,
      amount: order.amount,
      payment: order.payment,
      status: order.status
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteContact(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };
  const handleDeleteOrder = () => {
    var orderIds = selectedOrderId;
    axios.get(`${"api/companies/destroy/"}${orderIds}`)
      .then(response => {
        toast.success(response.message, { theme: "light" });
        loadTable();
        setDeleteModal(false)
      })
      .catch(error => {
        console.error(error);
      });
  };

  //react
  const smscolumn = useMemo(
    () => [

      {
        Header: "Site Name",
        accessor: "site_name",
        filterable: false,

      },
      {
        Header: "Site Address",
        accessor: "site_address",
        filterable: false,
      },
      {
        Header: "Zones",
        accessor: "zones",
        filterable: false,
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          console.log(cellProps);
          const site_id = cellProps.row.original.id;
          return (
            <div className="d-flex gap-3">
              <Link
                to="/apps-job-details"
                className="text-primary d-inline-block"
              >
                <i className="ri-eye-fill fs-16"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="edittooltip">
                View
              </UncontrolledTooltip>
              <Link
                to={`/editsite/general/${site_id}`}
                onClick={() => {
                  const customerData = cellProps.row.original;
                  handleContactClick(customerData);
                }}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },

    ],
    [handleContactClick, checkedAll]
  );
  //react
  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  //image selection
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);

  const handleButtonClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setUploadedImage(selectedFile);
      setSelectedImage(objectUrl);
      setIsImageSelected(true);
    }
  };

  const handleRemoveClick = () => {
    setSelectedImage(null);
    setUploadedImage(null);
    setIsImageSelected(false);
    fileInputRef.current.value = null; // Clear the input field value
  };

  const handleChangeClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          orderId={selectedOrderId}
          onDeleteClick={handleDeleteOrder}
          onCloseClick={() => setDeleteModal(false)}
        />
        <Col >
          <Card>
          <CardHeader>
          <div className="d-flex align-items-center flex-wrap gap-2">
            <div className="flex-grow-1">
            <h5 className="mb-0 text-primary">People</h5>
            </div>
            <div className="flex-shrink-0">
            </div>
          </div>
        </CardHeader>
            <CardHeader>
              <div className="d-flex align-items-center flex-wrap gap-2">
                <div className="d-flex justify-content-between align-items-center flex-grow-1">
                  <h5 className="mb-0 text-primary">
                    Sites
                  </h5>
                  <Link
                    to="/addsite"
                    className="text-primary d-inline-block"
                  >
                    <Button type="button" className="btn btn-info">Add Site</Button>
                  </Link>
                </div>
                <div className="flex-shrink-0">
                  <div className="hstack text-nowrap gap-2">
                    {isMultiDeleteButton && <button className="btn btn-danger"
                      onClick={() => setDeleteModalMulti(true)}
                    ><i className="ri-delete-bin-2-line"></i></button>}
                  </div>
                </div>
              </div>
            </CardHeader>
          </Card>

          <Card id="contactList">
            <CardBody className="pt-0">
              <div>
                {isContactSuccess && crmcontacts.length ? (
                  <TableContainer
                    columns={smscolumn}
                    data={(crmcontacts || [])}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    customPageSize={100}
                    className="custom-header-css"
                    divClass="table-responsive table-card mb-3"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light"
                    handleContactClick={handleContactClicks}
                    isContactsFilter={true}
                    SearchPlaceholder="Search for site..."
                  />
                ) : (<Loader />)
                }

              </div>
              <ToastContainer closeButton={false} limit={1} />

            </CardBody>
          </Card>

        </Col>
      </div>
    </React.Fragment >
  );
};
export default Sites;
