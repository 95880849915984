import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  UncontrolledPopover,
  Accordion,
  AccordionItem,
  Table,
  Collapse,
  Label,
  PopoverBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Flatpickr from "react-flatpickr";
import Cleave from "cleave.js/react";
import Loader from "../../Components/Common/Loader";
import { get_cookie } from "../../helpers/get_cookie";
import axios from "axios";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { Widgets } from "./Widgets";
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";

const Timesheets = () => {
  const user_id = JSON.parse(get_cookie("workauthUser")).user_id;

  const defaultStatusCounts = {
    Unsubmitted: 0,
    Submitted: 0,
    Unpaid: 0,
    Paid: 0,
  };

  const [coll2, setcoll2] = useState(false);
  const [view, setView] = useState("day");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [timesheetData, setTimesheetData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [totalBreaks, setTotalBreaks] = useState("");
  const [timesheetId, setTimeshettId] = useState("");
  const [shiftDate, setShiftDate] = useState("");
  const [shiftStatusCounts, setShiftStatusCounts] =
    useState(defaultStatusCounts);

  const toggleEditModal = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    getTimesheetData();
  }, []);

  const getTimesheetData = () => {
    setIsLoading(true);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const formData = {
      user_id: user_id,
      start_date: startDate ? startDate : moment().format("YYYY-MM-DD"),
      end_date: endDate ? endDate : moment().format("YYYY-MM-DD"),
    };
    axios
      .post("/list-timesheet-datas", formData)
      .then((res) => {
        console.log(res, "timesheet");
        setShiftStatusCounts(calculateShiftStatusCounts(res));
        setTimesheetData(res);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Widgets Count Calculations
  const calculateShiftStatusCounts = (timesheets) => {
    const newShiftStatusCounts = { ...defaultStatusCounts };

    // Submitted Count
    const submittedShifts = timesheets.filter(
      (shift) => shift.progress_status === "Submitted"
    );
    const countSubmitted = submittedShifts.length;
    newShiftStatusCounts["Submitted"] = countSubmitted;

    // Unsubmitted count
    const unsubmittedShifts = timesheets.filter((shift) =>
      [
        "Scheduled",
        "Attendance Uncertain",
        "Started",
        "Pending Submission",
        "Unsubmitted",
      ].includes(shift.progress_status)
    );
    const countUnsubmitted = unsubmittedShifts.length;
    newShiftStatusCounts["Unsubmitted"] = countUnsubmitted;

    // Paid count
    const paidShifts = timesheets.filter(
      (shift) => shift.progress_status === "Paid"
    );
    const countPaid = paidShifts.length;
    newShiftStatusCounts["Paid"] = countPaid;

    // Unpaid count
    const unpaidShifts = timesheets.filter(
      (shift) => shift.progress_status === "Pay Approved"
    );
    const countUnpaid = unpaidShifts.length;
    newShiftStatusCounts["Unpaid"] = countUnpaid;

    return newShiftStatusCounts;
  };

  const getEditData = (index, shift_id, timesheet_id) => {
    setIsModalLoading(true);
    setTimeshettId(timesheet_id);
    const formData = {
      user_id: user_id,
      shift_id: shift_id,
      timesheet_id: timesheet_id,
    };
    axios
      .post("/list-edit-timesheet-data", formData)
      .then((res) => {
        console.log(res);
        setShiftDate(res.shift_date);
        setStartTime(
          res.timesheet_start_time
            ? new Date(res.timesheet_start_time * 1000)
            : null
        );
        setEndTime(
          res.timesheet_end_time
            ? new Date(res.timesheet_end_time * 1000)
            : null
        );
        setTotalBreaks(res.breaks ? res.breaks.split(".")[0] : 0);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsModalLoading(false);
      });
  };

  const buttonClass = (buttonView) => {
    return view === buttonView
      ? "btn btn-primary selected"
      : "btn btn-soft-primary";
  };
  const flatpickrRef = useRef(null);
  const handleDateChange = (selectedDate) => {
    setCurrentDate(new Date(selectedDate));
    const date = new Date(selectedDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    let newStartDate, newEndDate;
    setSelectedDate(date);
    newStartDate = formattedDate;
    newEndDate = formattedDate;
    setStartDate(newStartDate); // Wait for the completion of setStartDate
    setEndDate(newEndDate);
    if (flatpickrRef.current !== null) {
      flatpickrRef.current.flatpickr.close();
    }
  };
  useEffect(() => {
    if (!selectedDate) {
      setSelectedDate(new Date());
    }
  }, []);
  const handleViewChange = (newView) => {
    let newSelectedDate = new Date();
    if (newView === "day") {
      // For day view, no need to change the selected date
    } else if (newView === "week") {
      // For week view, add 7 days to the selected date
      let currentDate = new Date();
      let currentDay = currentDate.getDay();
      let diff =
        currentDate.getDate() - currentDay + (currentDay == 0 ? -6 : 1); // Adjust to Monday if current day is Sunday
      newSelectedDate = new Date(currentDate.setDate(diff));
    } else {
      // For month view, set the selected date to the first day of the next month
      newSelectedDate.setDate(1);
    }

    // Set the selected date
    setSelectedDate(newSelectedDate);
    // Calculate start and end dates based on the selected view
    let newStartDate, newEndDate;
    if (newView === "day") {
      newStartDate = moment(newSelectedDate).format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).format("YYYY-MM-DD");
    } else if (newView === "week") {
      newStartDate = moment(newSelectedDate)
        .startOf("week")
        .format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).endOf("week").format("YYYY-MM-DD");
    } else {
      newStartDate = moment(newSelectedDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).endOf("month").format("YYYY-MM-DD");
    }

    // Call getTimesheetData with the calculated start and end dates
    setStartDate(newStartDate); // Wait for the completion of setStartDate
    setEndDate(newEndDate);

    // Update the view state
    setView(newView);
    // getTimesheetData();
  };
  const handleNext = async () => {
    let newSelectedDate;
    if (view === "day") {
      newSelectedDate = new Date(selectedDate.getTime() + 24 * 60 * 60 * 1000); // Next day
    } else if (view === "week") {
      newSelectedDate = new Date(
        selectedDate.getTime() + 7 * 24 * 60 * 60 * 1000
      ); // Next week
    } else if (view === "month") {
      newSelectedDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        1
      ); // Next month
    }
    setSelectedDate(newSelectedDate);
    updateStartAndEndDate(newSelectedDate);
  };
  const handlePrevious = async () => {
    let newSelectedDate;
    if (view === "day") {
      newSelectedDate = new Date(selectedDate.getTime() - 24 * 60 * 60 * 1000); // Previous day
    } else if (view === "week") {
      newSelectedDate = new Date(
        selectedDate.getTime() - 7 * 24 * 60 * 60 * 1000
      ); // Previous week
    } else if (view === "month") {
      newSelectedDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() - 1,
        1
      ); // Previous month
    }
    setSelectedDate(newSelectedDate);
    updateStartAndEndDate(newSelectedDate);
  };

  const updateStartAndEndDate = async (newSelectedDate) => {
    let newStartDate, newEndDate;
    if (view === "day") {
      newStartDate = moment(newSelectedDate).format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).format("YYYY-MM-DD");
    } else if (view === "week") {
      newStartDate = moment(newSelectedDate)
        .startOf("week")
        .format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).endOf("week").format("YYYY-MM-DD");
    } else if (view === "month") {
      newStartDate = moment(newSelectedDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).endOf("month").format("YYYY-MM-DD");
    }
    setStartDate(newStartDate); // Wait for the completion of setStartDate
    setEndDate(newEndDate);
  };

  useEffect(() => {
    // Ensure both startDate and endDate are not empty before sending data to the backend
    if (startDate && endDate) {
      // Call your backend function here, passing startDate and endDate
      getTimesheetData();
    }
  }, [startDate, endDate]);

  const handleStartTimeChange = (selectedDates) => {
    // selectedDates is an array of selected date objects
    const selectedTime = selectedDates[0];
    const formattedTime = moment(selectedTime).format("HH:mm");

    // Update the state with the new time in Unix format
    setStartTime(selectedTime);
    checkTimeDifference(selectedTime, endTime);
  };

  const handleEndTimeChange = (selectedDates) => {
    // selectedDates is an array of selected date objects
    const selectedTime = selectedDates[0];
    const formattedTime = moment(selectedTime).format("HH:mm");

    // Update the state with the new time in Unix format
    setEndTime(selectedTime);
    checkTimeDifference(startTime, selectedTime);

    console.log("Selected Time:", formattedTime); // For debugging
  };

  const checkTimeDifference = (start, end) => {
    if (start && end) {
      const startMoment = moment(start);
      const endMoment = moment(end);
      const differenceInMinutes = endMoment.diff(startMoment, "minutes");

      // Convert the difference to hours
      const differenceInHours = differenceInMinutes / 60;

      if (differenceInHours > 12) {
        toast.error("Shift duration exceeds the maximum allowed hours.");
      }
    }
  };

  const handleTimeChange = (event) => {
    setTotalBreaks(event.target.value);
  };

  const submitTimesheet = () => {
    setIsModalLoading(true);
    const shift_date = shiftDate;
    // Check if both start time and end time are present
    if (!startTime && endTime) {
      // Show toast error when endTime is provided but startTime is missing
      toast.error("Please select start time.");
      setIsModalLoading(false);
      return;
    } else if (startTime && endTime) {
      // Validate that end time is greater than start time
      if (endTime <= startTime) {
        toast.error("End time must be greater than start time.");
        setIsModalLoading(false);
        return;
      }
      checkTimeDifference(startTime, endTime);
    }

    const startTimeWithoutDate = startTime
      ? moment(startTime).format("HH:mm:ss")
      : null;
    const endTimeWithoutDate = endTime
      ? moment(endTime).format("HH:mm:ss")
      : null;
    // Parse the time strings using moment.js
    const startMoment = startTime
      ? moment(shift_date + " " + startTimeWithoutDate, "YYYY-MM-DD HH:mm:ss")
      : null;
    const endMoment = endTime
      ? moment(shift_date + " " + endTimeWithoutDate, "YYYY-MM-DD HH:mm:ss")
      : null;

    // Convert to UNIX timestamps
    const startUnix = startTime ? startMoment.unix() : null;
    const endUnix = endTime ? endMoment.unix() : null;
    // // Format the times as "HH:mm"
    // const formattedStartTime = startMoment.format("HH:mm");
    // const formattedEndTime = endMoment.format("HH:mm");

    const formattedTotalBreaks = `${totalBreaks
      .toString()
      .padStart(2, "0")}.00`;
    // Format the duration in "HH:mm" format
    var formData = {
      user_id: user_id,
      timesheet_id: timesheetId,
      timesheet_start_time: startUnix,
      timesheet_finish_time: endUnix,
      total_breaks: formattedTotalBreaks,
    };
    console.log(formData, "formData");
    axios
      .post("/submit-timesheet-data", formData)
      .then((res) => {
        toast.success(res.success, { theme: "light" });
        getTimesheetData();
        toggleEditModal();
      })
      .catch((error) => {
        toast.error("Something went wrong while editing shift...");
      })
      .finally(() => {
        setIsModalLoading(false);
      });
  };

  document.title = "Timesheets | Get Roster";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <BreadCrumb title="Timesheets" /> */}
          <Row>
            <Col>
              <Row>
                <Col lg={12}>
                  <Row className="ms-1 me-0">
                    <Widgets shiftStatusCounts={shiftStatusCounts} />
                  </Row>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col lg={12}>
                  <Card style={{ boxShadow: "none" }}>
                    <CardBody style={{ background: "#f3f6f9" }}>
                      <Row>
                        <Col
                          className={
                            "col-lg-12 custom-col-xxl-10 col-sm-9 pe-0 out-div "
                          }
                        >
                          <div className="week-view-calendar overflow-hidden w-100">
                            <div
                              className="navigation mb-4 d-flex"
                              style={{ justifyContent: "space-between" }}
                            >
                              <div className="">
                                <button
                                  className="btn btn-soft-primary p-2"
                                  onClick={handlePrevious}
                                >
                                  <i className="ri-arrow-left-s-line align-bottom fw-bold"></i>
                                </button>
                                <span
                                  className="btn btn-soft-primary ms-2"
                                  id="overviewCalendar"
                                >
                                  {view === "day" &&
                                    selectedDate instanceof Date &&
                                    selectedDate.toDateString()}
                                  {view === "week" && selectedDate && (
                                    <>
                                      {selectedDate.toDateString()} -{" "}
                                      {new Date(
                                        selectedDate.getTime() +
                                          6 * 24 * 60 * 60 * 1000
                                      ).toDateString()}
                                    </>
                                  )}
                                  {view === "month" &&
                                    selectedDate &&
                                    selectedDate.toLocaleString("default", {
                                      month: "long",
                                    })}
                                  <UncontrolledPopover
                                    placement="bottom"
                                    target="overviewCalendar"
                                    trigger="legacy"
                                  >
                                    <PopoverBody>
                                      <Flatpickr
                                        ref={flatpickrRef}
                                        name="unavailabilityDates"
                                        className="form-control"
                                        id="datepicker-overview-input"
                                        placeholder="Select date(s)"
                                        options={{
                                          mode: "single",
                                          enableTime: false,
                                          dateFormat:
                                            "D M d Y H:i:s \\G\\M\\TO (T)",
                                          inline: true,
                                          onClose: () =>
                                            console.log("Calendar closed"),
                                        }}
                                        onChange={handleDateChange}
                                        value={selectedDate || ""}
                                      />
                                    </PopoverBody>
                                  </UncontrolledPopover>
                                </span>
                                <button
                                  className="btn btn-soft-primary p-2 me-2 ms-2"
                                  onClick={handleNext}
                                >
                                  <i className="ri-arrow-right-s-line align-bottom fw-bold"></i>
                                </button>
                              </div>
                              <div>
                                <button
                                  className={buttonClass("day")}
                                  onClick={() => handleViewChange("day")}
                                >
                                  Day
                                </button>
                                <button
                                  className={buttonClass("week")}
                                  onClick={() => handleViewChange("week")}
                                >
                                  Week
                                </button>
                                <button
                                  className={buttonClass("month")}
                                  onClick={() => handleViewChange("month")}
                                >
                                  Month
                                </button>
                                <button
                                  className="btn btn-soft-primary p-2 ms-3 me-3"
                                  onClick={() => getTimesheetData()}
                                >
                                  <i className="bx bx-refresh align-inherit fs-16"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col
                          className={
                            "col-lg-12 custom-col-xxl-10 col-sm-9 pe-0 out-div "
                          }
                        >
                          <div className="table-responsive">
                            <Table className="table-striped table-nowrap align-middle mb-0">
                              <thead>
                                <tr>
                                  <th scope="col">Business</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Progress</th>
                                  <th scope="col">Time</th>
                                  <th scope="col">Hours</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              {timesheetData && timesheetData.length > 0 ? (
                                timesheetData.map((item, index) => (
                                  <tbody key={item.id}>
                                    <tr>
                                      <td>{item.business_name}</td>
                                      <td>
                                        {moment(item.shift_date).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td className="fw-medium">
                                        {item.approval_status}
                                      </td>
                                      <td>{item.progress_status}</td>
                                      <td>
                                        {item.timesheet_start_times == null &&
                                        item.timesheet_end_times == null
                                          ? "-"
                                          : item.timesheet_start_times !=
                                              null &&
                                            item.timesheet_end_times == null
                                          ? item.timesheet_start_times
                                          : item.timesheet_start_times ==
                                              null &&
                                            item.timesheet_end_times != null
                                          ? item.timesheet_end_times
                                          : `${new Date(
                                              item.timesheet_start_time * 1000
                                            ).toLocaleTimeString([], {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                            })} - ${new Date(
                                              item.timesheet_end_time * 1000
                                            ).toLocaleTimeString([], {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                            })}`}
                                      </td>

                                      <td>
                                        {item.hours_worked == null
                                          ? "-"
                                          : item.hours_worked}
                                      </td>
                                      <td className="ps-3">
                                        {console.log(
                                          item.progress_status,
                                          "item.progress_status"
                                        )}
                                        {[
                                          "Paid",
                                          "Pay Approved",
                                          "Submitted",
                                        ].includes(
                                          item.progress_status
                                        ) ? null : (
                                          <i
                                            className="text-primary bx bxs-pencil fs-20"
                                            onClick={() => {
                                              setModalOpen(true);
                                              getEditData(
                                                index,
                                                item.shift_id,
                                                item.id
                                              );
                                            }}
                                            style={{ cursor: "pointer" }}
                                          ></i>
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                ))
                              ) : (
                                <tbody>
                                  <tr>
                                    <td
                                      colSpan="7"
                                      style={{ textAlign: "center" }}
                                    >
                                      No data found
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999,
                  }}
                >
                  <Loader />
                </div>
              )}
            </Col>
          </Row>
        </Container>

        <Modal isOpen={modalOpen} toggle={toggleEditModal} centered size="md">
          <ModalHeader className=" position-relative" toggle={toggleEditModal}>
            Submit Timesheet
          </ModalHeader>
          <ModalBody>
            <Row className="mb-3">
              <Col lg={6}>
                <div className="d-flex align-items-center">
                  <Label
                    for="startTimeInput"
                    className="form-label mb-0"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Start Time
                  </Label>
                  <Flatpickr
                    className="form-control ms-4"
                    id="startTimeInput"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                    }}
                    // value={moment.unix(startTime).format("HH:mm")}
                    value={startTime}
                    onChange={handleStartTimeChange}
                  />
                </div>
              </Col>

              <Col lg={6}>
                <div className="d-flex align-items-center">
                  <Label
                    for="endTimeInput"
                    className="form-label mb-0"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    End Time
                  </Label>
                  <Flatpickr
                    className="form-control ms-4"
                    id="endTimeInput"
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                    }}
                    value={endTime}
                    onChange={handleEndTimeChange}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={8}>
                <div className="d-flex align-items-center">
                  <Label
                    for="totalBreaksInput"
                    className="form-label mb-0"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Total Breaks
                  </Label>
                  <select
                    className="form-select ms-3"
                    value={totalBreaks ? totalBreaks.split(".")[0] : 0}
                    onChange={(e) => {
                      setTotalBreaks(e.target.value);
                    }}
                  >
                    <option value="">Select Break minutes</option>
                    {[...Array(48)].map((_, i) => (
                      <option key={i} value={(i + 1) * 5}>
                        {(i + 1) * 5} mins
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col lg={4}></Col>
            </Row>
            <Row>
              <Col lg={12} className="d-flex justify-content-center">
                <button
                  className="btn btn-primary ps-5 pe-5 pt-2 pb-2"
                  onClick={() => submitTimesheet()}
                >
                  Submit
                </button>
              </Col>
            </Row>
            {isModalLoading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "rgba(255, 255, 255, 0.8)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 9999,
                }}
              >
                <Loader />
              </div>
            )}
          </ModalBody>
        </Modal>
        <ToastContainer closeButton={false} limit={1} />
      </div>
    </React.Fragment>
  );
};

export default Timesheets;
