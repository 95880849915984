import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, Alert, UncontrolledAlert, TabContent, TabPane, UncontrolledTooltip } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo,useRef} from "react";
import { Button } from 'reactstrap';
import { isEmpty } from "lodash";
import "../../../assets/scss/pages/_settings.scss";
import {
  CardHeader,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
//dateformat
import moment from 'moment';
import { get_cookie } from '../../../helpers/get_cookie';
//reloading the page
import { jsx } from '@emotion/react'
import { ClipLoader } from 'react-spinners';
//toast
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../Components/Common/Loader";
import Dropzone from "react-dropzone";
import uploadImg from '../../../assets/images/freepik/uploading_img.png';
import img13 from "../../../assets/images/small/img-12.jpg";
import { api } from "../../../config";
import GoogleAutocomplete from "react-google-autocomplete";
//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export const General = () => {
  document.title="General | Onexfort";
 //paramas used for general
  const [isLoading, setisLoading] = useState(false);
  const [business_name, setBusinessName] = useState("");
  const [business_size, setBusinessSize] = useState("");
  const [sys_industry_id, setSysIndustryId] = useState("");
  const [industry, setIndustry] = useState("");
  const [address, setAddress] = useState("");
  const [logo, setLogo] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  //validation params
  const [businessNameError, setBusinessNameError] = useState('');
  const [businessSizeError, setBusinessSizeError] = useState('');
  const [sysIndustryIdError, setSysIndustryIdError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [logoError, setLogoError] = useState('');
  const [id,setId] = useState('');
  const [gld,setGld] = useState('');

    //pro set
      //google address
      const [selectedCity, setSelectedcity] = useState();
      const [selectedSuburb, setselectedSuburb] = useState();
      const [selectedPostcode, setselectedPostcode] = useState();
      const [country, setCountry] = useState([]);
      const residentialplace = (place) => {
      const addressComponents = place?.address_components;
        console.log(place);
    const streetNumberComponent = addressComponents?.find((component) =>
      component.types.includes("street_number")
    );
    const streetRouteComponent = addressComponents?.find((component) =>
      component.types.includes("route")
    );
    const unitComponent = place?.addressComponents?.find((component) =>
      component.types.includes("subpremise")
    );
    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );
    const countryComponent = addressComponents?.find((component) =>
      component.types.includes("country")
    );

    let selectedStreetNumber = "";
    if (streetNumberComponent) {
      selectedStreetNumber = streetNumberComponent.long_name;
    }
    let selectedUnitNumber = "";
    if (unitComponent) {
      selectedUnitNumber = unitComponent.long_name;
    }
    let selectedStreetRoute = "";
    if (streetRouteComponent) {
      selectedStreetRoute = streetRouteComponent.long_name;
    }
    let selectedSuburb = "";
    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;
    }
    let selectedState = "";
    if (stateComponent) {
      selectedState = stateComponent.short_name;
    }
    let selectedPostcode = "";
    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }
    let selectedcountry = "";
    if (countryComponent) {
      selectedcountry = countryComponent.long_name;
    }

    setSelectedcity(`${selectedUnitNumber} ${selectedStreetNumber} ${selectedStreetRoute} `);
    setselectedSuburb(selectedSuburb + ", " + selectedState);
    setselectedPostcode(selectedPostcode);
    setCountry(selectedcountry);
    setAddress(place?.formatted_address);

  };
  // Your list of options with specific values
  const sizeOptions = [
    { value: '1-10', label: '1-10' },
    { value: '11-50', label: '11-50' },
    { value: '51-100', label: '51-100' },
    { value: '101-200', label: '101-200' },
    { value: '201-500', label: '201-500' },
    { value: '501-1000', label: '501-1000' },
    { value: '1000+', label: '1000+' },
  ];
    //end of google address
    //proname
     const handleNameChange = (e) => {
      setBusinessName(e.target.value);
    };
    //proemail
    const handleSizeChange = (selectedOption) => {
      setBusinessSize(selectedOption.value);
    };
    //promobile
    const handleIndustryChange = (selectedOption) => {
      setSysIndustryId({ value: selectedOption.value, label: selectedOption.label });
    };
    //progender
     const handleAddressChange = (e) => {
      setAddress(e.target.value);
    };
    const loadGeneral = () => {
    setisLoading(true);
    axios.get('/get-general-data')
      .then(response => {
        setIndustry(response.industries);
        setGld(response.general_data);
         setAddress(response.full_address);
        setCountry(response.country_name);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      })
  }
  //*backend response*
  useEffect(() => {
    loadGeneral();
  }, []);
  useEffect(() => {
    if (gld) {
    setId(gld?.id);
    setBusinessName(gld.business_name);
    if (Array.isArray(industry)) {
        setBusinessSize(gld.business_size);
          const selecteOption = industry.find(item => item.id === gld.sys_industry_id);
          if (selecteOption) {
            setSysIndustryId({ value: gld.sys_industry_id, label: selecteOption.name });
            }
          }
        setImage(gld.business_logo);
        setSelectedcity(gld.address);
        setselectedSuburb(gld.suburb);
        setselectedPostcode(gld.post_code);
      }
  }, [gld]);
    const handleSubmit = (event) => {
    //event.preventDefault();
      setisLoading(true);
    /* validation */
    if (!business_name.trim()) {
            setBusinessNameError('Please enter your business name.');
        } else {
            setBusinessNameError('');
        }
    if (!business_size.trim()) {
            setBusinessSizeError('Please select your business size.');
        } else {
            setBusinessSizeError('');
        }
    if (typeof sys_industry_id !== 'string' || !sys_industry_id.trim()) {
        setSysIndustryIdError('Please select Industry.');
      } else {
        setSysIndustryIdError('');
      }
    if (!address.trim()) {
            setAddressError('Please enter your address.');
        } else {
            setAddressError('');
        }
    
    var formData = new FormData();
    formData.append("business_name", business_name);
    formData.append("business_size", business_size);
    formData.append("sys_industry_id", sys_industry_id.value);
    formData.append("address", selectedCity);
    formData.append("suburb", selectedSuburb);
    formData.append("post_code", selectedPostcode);
    formData.append("country_name", country);
    formData.append("business_logo", uploadedImage);
    if (id) {
      // If setId(gld.id) is not empty, update the data
      formData.append("id", id); 
    }
    axios.post('/api/update-general-data', formData)
      .then(response => {
        toast.success(response.success, { theme: "light" });
        loadGeneral();
      })
      .catch(error => {
        console.error(error);
        toast.error('Something went wrong...');
      })
      .finally(() => {
        setisLoading(false); //reloading the page
      });
  }
 // file upload content
  const [imagesel, setImage] = useState("");
  const [uploadedImage, setUploadedImage] = useState("");
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [imageDimensionsValid, setImageDimensionsValid] = useState(true);

   const handleButtonClick = (event) => {
     event.preventDefault();
     fileInputRef.current.click();
   };

 const handleFileInputChange = (event) => {
   const selectedFile = event.target.files[0];
   if(selectedFile){
    // Check file type
      const acceptedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
      if (!acceptedFormats.includes(selectedFile.type)) {
        setLogoError('Please select a valid image format (jpeg, jpg, png).');
        setUploadedImage(null);
      }else {
            setLogoError('');
        }
      // Check image dimensions
      const image = new Image();
      image.src = URL.createObjectURL(selectedFile);

      image.onload = () => {
        if (image.width > 250 || image.height > 100) {
          setLogoError('Image dimensions should not exceed 250px width and 100px height.');
          setImageDimensionsValid(false);
        } else {
           const objectUrl = URL.createObjectURL(selectedFile);
           setUploadedImage(selectedFile);
           setSelectedImage(objectUrl);
           setIsImageSelected(true);
           setImageDimensionsValid(true);
        }
      };
  }
 };

 const handleRemoveClick = () => {
   setSelectedImage(null);
   setUploadedImage(null);
   setIsImageSelected(false);
   fileInputRef.current.value = null; // Clear the input field value
 };

 const handleChangeClick = (event) => {
   event.preventDefault();
   fileInputRef.current.click();
 };
  //end of file upload content
  //end of params
  return (
    <div>
      <Card id="contactList">
        <CardHeader><h5 className="mb-0 text-primary">General</h5></CardHeader>
        <CardBody>
          <Col xxl={12}>

            <div className="live-preview">
              <Row className="gy-4">
                <Col xxl={12} md={12}>
                  <div>
                    <Label htmlFor="basiInput" className="form-label">Business Name <span className="text-danger">*</span></Label>
                    <Input type="text" value={business_name} onChange={handleNameChange} className={`${businessNameError ? 'is-invalid' : ''}`} id="readonlyInput" />
                    {businessNameError && <div className="invalid-feedback">{businessNameError}</div>}
                  </div>
                </Col>
                <Col xxl={12} md={12}>
                  <div>
                    <Label htmlFor="readonlyInput" className="form-label">Business Size <span className="text-danger">*</span></Label>
                    <Select className={` ${businessSizeError ? 'is-invalid' : ''}`}
                    value={sizeOptions.find(option => option.value === business_size)}
                    onChange={handleSizeChange}
                    options={sizeOptions}
                    isSearchable={true}
                  />
                  {businessSizeError && <div className="invalid-feedback">{businessSizeError}</div>}
                  </div>
                </Col>
                <Col xxl={12} md={12}>
                  <div>
                    <Label htmlFor="readonlyInput" className="form-label">Industry <span className="text-danger">*</span></Label>
                    <Select className={` ${sysIndustryIdError ? 'is-invalid' : ''}`}
                    value={sys_industry_id}
                    onChange={handleIndustryChange}
                    options={industry ? industry.map(item => ({ value: item.id, label: item.name })) : []}
                    isSearchable={true}
                  />
                  {sysIndustryIdError && <div className="invalid-feedback">{sysIndustryIdError}</div>}
                  </div>
                </Col>

                <Col xxl={12} md={12}>
                  <div >
                    <Label htmlFor="choices-single-default" className="form-label">Address <span className="text-danger">*</span></Label>
                    <div>
                    <GoogleAutocomplete className={`${addressError ? 'is-invalid' : ''}`}
                        apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        onPlaceSelected={(place) => residentialplace(place)}
                        options={{
                          types: ["address"],
                          componentRestrictions: {
                            country: "au",
                          },
                        }}
                        style={{
                          width:"100%",
                          border: "1px solid #ccc",
                          padding: "10px",
                          borderRadius: "5px",
                          position: "relative",
                        }}
                      />
                      {addressError && <div className="invalid-feedback">{addressError}</div>}
                      </div>
                  </div>

                </Col>
                <Col lg={7}>
                  <div className="mb-3">
                    <Label htmlFor="choices-single-default" className="form-label">
                      Logo
                    </Label>
                    <Row className="mb-2">
                      <Col md={4}>
                        <div className="img-container">
                          {imagesel ? (
                                <img
                                className="img-thumbnail"
                                alt="Company Logo"
                                src={
                                  isImageSelected
                                    ? selectedImage
                                    : api.WEB_URL+"user-uploads/business_logo/"+imagesel
                                }
                              />
                              ) : (
                                <>
                                {isImageSelected ? (
                                  <img
                                    className="img-thumbnail"
                                    alt="Company Logo"
                                    src={selectedImage}
                                  />
                                ) : (
                                  <>
                                    {/*<img
                                      className="img-thumbnail"
                                      alt="Company Logo"
                                      src={img13}
                                    />*/}
                                    {/*<div className="img-text">Upload Logo</div>*/}
                                  </>
                                )}
                              </>
                            )}
                        </div>
                      </Col>
                    </Row>
                    {isImageSelected ? (
                      <div>
                        <button
                          className="btn btn-primary change-button"
                          onClick={handleChangeClick}
                        >
                          Change
                        </button>
                        <button
                          className="btn btn-danger remove-button"
                          onClick={handleRemoveClick}
                        >
                          Remove
                        </button>
                      </div>
                    ) : (
                      <button
                        className="btn btn-info"
                        onClick={handleButtonClick}
                      >
                        Select image
                      </button>
                    )}
                    <input className={`${logoError ? 'is-invalid' : ''}`}
                      type="file"
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                      onChange={handleFileInputChange}
                      accept=".jpeg, .jpg, .png"
                    />
                    {!imageDimensionsValid && (
                       <div className="invalid-feedback">{logoError}</div>
                    )}
                  </div>
                </Col>

              </Row>
            </div>

          </Col>
          <hr></hr>
          <ToastContainer closeButton={false} limit={1} theme="light" />
           {/* reloading the page */}
           {isUpdating && (
                <div className="spinner-overlay">
                  <ClipLoader css={override} size={35} color={'#123abc'} loading={true} />
                </div>
              )}
          <div className="text-center">
            <Button className="btn btn-success" onClick={handleSubmit} disabled={isUpdating}>Apply Changes</Button> &nbsp;
            {/*<Button className="btn btn-dark" onClick={handleReset}>Reset</Button>*/}
          </div>
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  )
}
