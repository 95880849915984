
export const SettingsTabPages = {
    General: {
        id: 0,
        name:"general",
        displayText: 'General',
        iconClass: 'cog'
    },
    Zone: {
        id: 1,
        name:"zone",
        displayText: 'Zone',
        iconClass: 'user'
    },
}

