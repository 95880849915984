import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Input,
  Accordion,
  AccordionItem,
  Collapse,
  Label,
} from "reactstrap";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
export const Widgets = ({ shiftStatusCounts }) => {
  return (
    <>
      <>
        <Row>
          <Col xl={12} className="pe-0 ps-0">
            <Card className="crm-widget mt-2">
              <CardBody className="p-0">
                <Row className="justify-content-between">
                  {shiftStatusCounts &&
                    Object.entries(shiftStatusCounts).map(([status, count]) => (
                      <Col
                        className={count <= 4 ? "col-lg-3" : "col-lg-3 border-end"}
                        key={status}
                      >
                        <div className="mt-3 mt-md-0 py-3 px-3">
                          <div className="d-flex align-items-center justify-content-between">
                            <h5 className="text-muted text-uppercase fs-13 mb-0">
                              {status}{" "}
                            </h5>

                            <h5 className="mb-0 roster-count-view text-center">
                             
                                <CountUp
                                  start={0}
                                  end={count}
                                  decimals={0}
                                  duration={4}
                                />
                             
                            </h5>
                          </div>
                        </div>
                      </Col>
                    ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    </>
  );
};
