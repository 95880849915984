import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import "../css/TermsOfUse.css"; // Import the CSS file for styling

const PrivacyPolicy = () => {
  return (
    <Card id="contactList">
      <CardBody>
        <div className="content">
          <h2 className="mb-0 text-primary">Privacy Policy</h2>
          <div className="text-align-justify">
            <p className="paragraph text-justify text-align-justify mt-3">
              This is the privacy notice of Get Rostered Pty Ltd. In this
              document, "we", "us", and "our" refer to Get Rostered Pty Ltd,
              registered in Australia with ACN 676 787 554.
            </p>
            <p className="paragraph text-justify">
              Our registered office is at 16 Kokoda Place, Mordialloc, VIC 3195,
              Australia.
            </p>
            <p className="paragraph text-justify">
              This notice outlines our policy concerning all information that we
              record about you. It addresses both information that could
              identify you and information that could not.
            </p>
            <p className="paragraph ">
              We are committed to protecting your privacy and confidentiality.
              We understand the importance of privacy to our website users and
              uphold strict privacy policies in line with Australian law and
              applicable international laws. If you believe our policy falls
              short of your expectations or if we fail to abide by our policy,
              please contact us immediately.
            </p>
            <p className="paragraph text-justify">
              We regret that if there are one or more points below you disagree
              with, your only recourse is to leave our website immediately.
            </p>
            <p className="paragraph text-justify">
              Except as outlined below, we do not share, sell, or disclose to a
              third party, any personally identifiable information collected at
              this site.
            </p>
            <div className="hstack gap-3 text-align-center">
              <h4 className="mb-0 text-primary">
                Information We Collect and Its Uses
              </h4>
              <hr className="w-50"></hr>
            </div>

            <p className="paragraph text-muted mt-2">
              We collect information through our website or directly from you in
              the following ways:
            </p>
            <p className="listItem">
              <strong>Business and Personal Information: </strong>
              This includes your name, contact details, and information you
              provide us in your capacity as our client. This information is
              used:
            </p>
            <ul className="subList">
              <li>To provide you with the services you request;</li>
              <li>To verify your identity for security purposes;</li>
              <li>For marketing our services and products;</li>
              <li>For general market and demographic analysis.</li>
            </ul>
            <p className="paragraph">
              We retain business records for a minimum of six years to support
              potential claims or defenses in court and to comply with tax
              regulations.
            </p>
            <p className="listItem">
              <strong>Marketplace Information: </strong>
              We collect information to facilitate transactions on our platform,
              assuming consent to pass this to relevant parties as necessary.
            </p>
            <p className="listItem">
              <strong>Your Domain Name and Email Address: </strong>
              Recorded by our servers and used for communication and site
              improvement purposes without disclosure outside our company except
              as legally required.
            </p>
            <p className="listItem">
              <strong>Financial Information: </strong>
              We do not collect or have access to credit card details. Payment
              processing is handled through secure third-party services like
              Stripe, PayPal, or other reputable providers.
            </p>
            <p className="listItem">
              <strong>Cookies: </strong>
              We use cookies to improve site functionality and user experience.
              You may disable cookies in your browser settings, though this may
              affect website functionality.
            </p>
            <p className="listItem">
              <strong>Use of Information by Third Parties: </strong>
              We do not control the data collection or privacy practices of
              third-party advertisers or services linked from our site.
            </p>
            <p className="listItem">
              <strong>Children’s Privacy: </strong>
              Our services are not marketed to children under the age of 18
              without parental consent.
            </p>
            <p className="listItem">
              <strong>Disclosure to Authorities: </strong>
              We may disclose information if legally required to do so.
            </p>
            <p className="listItem">
              <strong>Data Processing Outside Australia: </strong>
              Data may be processed outside of Australia as part of our
              international operations.
            </p>
            <div className="hstack gap-3 text-align-center">
              <h4 className="mb-0 text-primary">Data Security</h4>
              <hr className="w-75"></hr>
            </div>

            <p className="paragraph">
              We employ secure data handling procedures to protect personal and
              sensitive user data. This includes encryption, access controls,
              and secure servers.
            </p>
            <div className="hstack gap-3 text-align-center">
              <h4 className="mb-0 text-primary">Data Retention and Deletion</h4>
              <hr className="w-60"></hr>
            </div>
            <p className="paragraph">
              We retain personal and sensitive user data for as long as
              necessary to fulfill the purposes outlined in this policy, comply
              with legal obligations, resolve disputes, and enforce our
              agreements. Users may request the deletion of their data by
              contacting us at{" "}
              <a href="mailto:support@getrostered.app" className="text-decoration-underline text-info">
                support@getrostered.app
              </a>
              .
            </p>
            <div className="hstack gap-3 text-align-center">
              <h4 className="mb-0 text-primary">Complaints and Contact</h4>
              <hr className="w-65"></hr>
            </div>

            <p className="paragraph">
              If you have privacy concerns or complaints, you may contact us at{" "}
              <a href="mailto:support@getrostered.app" className="text-decoration-underline text-info">
                support@getrostered.app
              </a>
            </p>
            <p className="paragraph">
              Changes to this policy may occur, and we encourage you to review
              it periodically.
            </p>
            <p className="paragraph">
              For further information, please contact us through our website's
              contact page.
            </p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PrivacyPolicy;
