import { Card, CardBody } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo,useRef } from "react";
//import TableRows from "../TableRows";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  CardHeader,
  Table,
} from "reactstrap";
//Import actions

//redux
import { useSelector, useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Roster = () => {
  document.title="Roster | Onexfort";
  return (
    <div>
      <Card id="contactList">
        <CardHeader>
          <h5 className="mb-0 text-primary">Roster</h5>
        </CardHeader>
        <CardBody className="pt-0 mt-3">
        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} theme="light" />
    </div>
  )
}
