import React from 'react'
import { Card } from 'reactstrap'
import { SettingsTabPages } from '../Constants/SettingsConstants';
import { General } from '../TabPages/General';
import { People } from '../TabPages/People';
import { Roster } from '../TabPages/Roster';
import { Timesheets } from '../TabPages/Timesheets';
import { Leave } from '../TabPages/Leave';
export default function SettingsTabContent({ activeTab }) {

    const TabOutlet = () => {
        switch (activeTab) {
            case SettingsTabPages.General:
                return <General />
            case SettingsTabPages.People:
                return <People />
            case SettingsTabPages.Roster:
                return <Roster />
            case SettingsTabPages.Timesheets:
                return <Timesheets />
            case SettingsTabPages.Leave:
                return <Leave />
            default:
                return <General />
        }
    }

    return (
        //Fragment just in case
        <>
            {TabOutlet()}
        </>

    )
}
